<template>
  <div class="navigate-bottom">
    <p class="shadow-text" :style="textColorStyle">
      ©2024 三生寻 All rights reserved 丨<a @click="goIcp" class="link">苏ICP备19058043号-3</a>  丨 <a @click="goHere" class="link">在这里资源站</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'NavigateBottom',
  data() {
    return {};
  },
  computed: {
    // 判断当前页面是否是首页
    isHomePage() {
      // 假设首页的路径是 '/' /todayThing
      return this.$route.path === "/" || this.$route.path === "/tarot"|| this.$route.path === "/constellation"|| this.$route.path === "/constellation/constellation-detail"|| this.$route.path === "/tarot/tarot-card"|| this.$route.path === "/bell"|| this.$route.path === "/todayThing";
    },
    textColorStyle() {
      // 根据是否是首页来设置文字颜色
      console.log(this.isHomePage,'this.$route.path');
      
      return {
        color: this.isHomePage ?'#ffffff'  : '#050A1C' // 或者指定其他非首页的颜色
      };
    }
  },
  methods: {
    goHere(event) {
      event.preventDefault(); // 防止链接的默认行为
      window.open('https://www.hereitis.cn/', '_blank');
    },
    goIcp(event){
      event.preventDefault(); // 防止链接的默认行为
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
    }
  }
};
</script>
<style lang="scss" scoped>
.navigate-bottom {
  width: 100vw;
  height: 1.875rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0rem;
  z-index: 99;
  
  p {
    width: 28.5rem;
    height: 1rem;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 0.75rem;
    // color: #050A1C;
    line-height: 1.25rem;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .shadow-text {
  /* 添加文字阴影样式 */
  // text-shadow: 1px 1px 1px rgba(255, 255, 255, 1); /* 水平阴影, 垂直阴影, 模糊距离, 阴影颜色 */
}
  .link {
  text-decoration: none; /* 默认不显示下划线 */
  transition: text-decoration 0.3s ease; /* 添加过渡效果 */
}

.link:hover {
  text-decoration: underline; /* 鼠标悬停时显示下划线 */
  color: #007bff;  /* 设置链接颜色，可以根据需要调整 */
}
}
</style>