import axios from "axios";
import Vue from 'vue';

let baseUrl = "https://clanss.com/sanshengxun";
Vue.prototype.$API_BASE_URL = baseUrl;

export const Service = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
});

// http request 请求拦截器
Service.interceptors.request.use(
  (config) => {
    // 从本地存储获取Token
    const token = localStorage.getItem('tokenValue'); // 使用正确的 token 键名
    // console.log('token', token, localStorage);

    if (token) {
      this.$IsToken='true';
      // 如果Token存在，则添加到请求头中
      config.headers['sanshengxun-web'] = `${token}`;
    }
    
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

Service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 处理响应错误...
    return Promise.reject(error);
  }
);