import Vue from 'vue';
import Router from 'vue-router';

// 导入页面组件
import HomePage from '@/pages/home/home-page.vue';
import PreloadPage from '@/pages/preload/preload-page.vue'; // 预加载页面

Vue.use(Router);

const router = new Router({
  mode: 'history', // 使用 HTML5 History 模式
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/preload',
      name: 'preload',
      component: PreloadPage,
      meta: { title: '加载中' }
    },
    {
      path: '/',
      name: 'Home',
      component: HomePage,
      meta: { title: '首页' }
    },
    {
      path: '/bell',
      component: () => import('@/pages/bell/bell-index.vue')
    },
    {
      path: '/wish',
      component: () => import('@/pages/wish/wishIndex/wish-index.vue')
    },
    {
      path: '/constellation',
      component: () => import('@/pages/constellation/constellationhome/constellation-home.vue'),
      children: [
        {
          path: '/',
          name: 'ConstellationIndex',
          component: () => import('@/pages/constellation/constellationIndex/constellation-index.vue')
        },
        {
          path: 'constellation-detail',
          name: 'ConstellationDetail',
          component: () => import('@/pages/constellation/constellationDetail/constellation-detail.vue')
        },
        {
          path: 'constellation-star',
          name: 'ConstellationStar',
          component: () => import('@/pages/constellation/constellStar/constell-star.vue')
        }
      ]
    },
    {
      path: '/creatures',
      component: () => import('@/pages/creatures/creatures-index.vue')
    },
    {
      path: '/getName',
      component: () => import('@/pages/getName/getNameHome/name-home.vue'),
      children: [
        {
          path: '/',
          name: 'getNameIndex',
          component: () => import('@/pages/getName/index/get-name.vue')
        },
        {
          path: 'names-list',
          name: 'namesList',
          component: () => import('@/pages/getName/namesList/names-list.vue')
        },
        {
          path: 'names-detail',
          name: 'namesDetail',
          component: () => import('@/pages/getName/nameDetail/name-detail.vue')
        }
      ]
    },
    {
      path: '/tarot',
      component: () => import('@/pages/tarot/tarothome/tarot-home.vue'),
      children: [
        {
          path: '/',
          component: () => import('@/pages/tarot/tarotIndex/tarot-index.vue'),
        },
        {
          path: 'tarot-card',
          name: 'tarotCard',
          component: () => import('@/pages/tarot/tarotCard/tarot-card.vue')
        },
      ]
    },
   
    {
      path: '/todayThing',
      component: () => import('@/pages/todaything/today-thing.vue')
    },
    {
      path: '/draw',
      component: () => import('@/pages/draw/drawhome/draw-home.vue'),
      children: [
        {
          path: '/',
          name: 'drawIndex',
          component: () => import('@/pages/draw/drawIndex/draw-index.vue')
        },
        {
          path: 'de-visa',
          name: 'deVisa',
          component: () => import('@/pages/draw/deVisa/de-visa.vue')
        },
        {
          path: 'daily-signature',
          name: 'dailySignature',
          component: () => import('@/pages/draw/dailySignature/daily-signature.vue')
        }
      ]
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});



export default router;