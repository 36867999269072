var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"themes"},[_c('div',{staticClass:"themes-top"},[_c('div',{attrs:{"id":"show"},on:{"mousedown":_vm.showMouseDown,"mousemove":_vm.showMouseMove,"mouseup":_vm.showMouseUp,"click":_vm.showClick}},_vm._l((_vm.items),function(item,index){return _c('div',{key:item.id,class:'item item' + index,style:({
            top: item.top + 'px',
            left: item.left + 'px',
            zIndex: item.Zindex,
            width: item.width + 'px',
            height: item.height + 'px',
            opacity: item.opacity,
            transform: 'rotate(' + item.rotate + 'deg)',
          }),on:{"click":function($event){return _vm.itemClick(item)}}},[_c('div',{staticClass:"items-box"})])}),0),_c('div',{staticClass:"touch-bottom",on:{"click":function($event){return _vm.themsClick()}}},[_vm._m(0),_c('div',{staticClass:"tb-bottom",on:{"click":function($event){return _vm.themsClick()}}},[_vm._v(" "+_vm._s(_vm.themesName)+" ")])])]),_c('div',{staticClass:"themes-center"},[_c('div',{staticClass:"sign",on:{"click":function($event){return _vm.drawClick()}}},[_vm._m(1)]),_c('div',{staticClass:"bell",on:{"click":function($event){return _vm.bellClick()}}},[_vm._m(2)]),_c('div',{staticClass:"today-thing",on:{"click":function($event){return _vm.todayThingClick()}}},[_vm._m(3)])])]),_c('router-view')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tb-top"},[_c('div',{staticClass:"t-border"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-img"},[_c('img',{attrs:{"src":"https://www.hereitis.cn/profile/adminUpload/2024/07/30/c44025e273f448eeb69eb57d6133a69b.png","alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bell-img"},[_c('img',{attrs:{"src":"https://www.hereitis.cn/profile/adminUpload/2024/07/30/0ffc802d8f054d72ae939e350b4c61bf.png","alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"today-thing-img"},[_c('img',{attrs:{"src":"https://www.hereitis.cn/profile/adminUpload/2024/07/30/27b580746d2f4b308c7e4ef16bba84bc.png","alt":"","srcset":""}})])
}]

export { render, staticRenderFns }