<template>
  <div class="preload-bird">
    <div class="world"> 
      
      <div class="birdposition">
        <div class="bird">
          <div class="wing -left"></div>
          <div class="wing -right"></div>
        </div>
      </div>
      <div class="birdposition">
        <div class="bird">
          <div class="wing -left"></div>
          <div class="wing -right"></div>
        </div>
      </div>
      <div class="birdposition">
        <div class="bird">
          <div class="wing -left"></div>
          <div class="wing -right"></div>
        </div>
      </div>
      <div class="birdposition">
        <div class="bird">
          <div class="wing -left"></div>
          <div class="wing -right"></div>
        </div>
      </div>
      <div class="birdposition">
        <div class="bird">
          <div class="wing -left"></div>
          <div class="wing -right"></div>
        </div>
      </div>
      <div class="birdposition">
        <div class="bird">
          <div class="wing -left"></div>
          <div class="wing -right"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'preload-bird',
  data () {
    return {
      
    }
  }
};
</script>

<style>
.preload-bird {
  /* background: #fff; */
  width: 50vw;
  height: 50vh;
  /* position: absolute;
  top: 0;
  left: 0;
  overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(.3);
}

div {
  transform-style: preserve-3d;
}

.world {
  perspective: 600px;
}

.sun {
  position: absolute;
  top: calc(50% + 40px);
  left: calc(50% - 50px);
  width: 100px;
  height: 55px;
  border-radius: 100px 100px 0 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, white 100%);
  z-index: 999;
  filter: blur(4px) contrast(2);
}

.bg {
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 400px);
  width: 800px;
  height: 300px;
  background: yellow;
  filter: blur(5px) contrast(100);
}

.wave {
  position: absolute;
  top: calc(50% + 87px);
  left: calc(50% - 60px);
  width: 120px;
  height: 12px;
  background: #fff;
  border-radius: 100%;
}
.wave:not(:first-child) {
  -webkit-animation: wave 2000ms infinite linear;
  animation: wave 2000ms infinite linear;
}
.wave:nth-child(2) {
  -webkit-animation-delay: -500ms;
  animation-delay: -500ms;
}
.wave:nth-child(3) {
  -webkit-animation-delay: -1000ms;
  animation-delay: -1000ms;
}
.wave:nth-child(4) {
  -webkit-animation-delay: -1500ms;
  animation-delay: -1500ms;
}
.wave:nth-child(5) {
  -webkit-animation-delay: -1500ms;
  animation-delay: -1500ms;
}
.wave:nth-child(6) {
  -webkit-animation-delay: -2000ms;
  animation-delay: -2000ms;
}

@-webkit-keyframes wave {
  0% {
    transform: translateY(0) sclae(1) rotateZ(0);
  }
  50% {
    transform: translateY(40px) scale(0.5) rotateZ(5deg);
  }
  100% {
    transform: translateY(30px) scale(0) rotateZ(-40deg);
  }
}

@keyframes wave {
  0% {
    transform: translateY(0) sclae(1) rotateZ(0);
  }
  50% {
    transform: translateY(40px) scale(0.5) rotateZ(5deg);
  }
  100% {
    transform: translateY(30px) scale(0) rotateZ(-40deg);
  }
}
.sea {
  position: absolute;
  top: calc(50% + 80px);
  left: calc(50% - 400px);
  width: 800px;
  height: 70px;
  background: rgba(0, 0, 255, 0.5);
  background: radial-gradient(
    ellipse at top,
    white 0%,
    rgba(255, 215, 0, 0.1) 10%,
    chocolate 100%
  );
}

.sky {
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 400px);
  width: 800px;
  height: 240px;
  background: rgba(0, 0, 255, 0.5);
  background: radial-gradient(
    ellipse at bottom,
    yellow 0%,
    khaki 15%,
    orange 100%
  );
}

.horizon {
  position: absolute;
  top: 50%;
  width: 800px;
  height: 200px;
  border-radius: 100%;
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  filter: blur(10px);
}

.sunset {
  position: relative;
  width: 800px;
  height: 300px;
  overflow: hidden;
  border-radius: 7px;
}

.line {
  position: absolute;
  top: calc(50%);
  left: calc(50% - 7px);
  width: 14px;
  height: calc(300px * 0.5);
  background: white;
}
.line.-left {
  transform: translateZ(300px) translateX(-70px) translateY(-50%);
}
.line.-right {
  transform: translateZ(300px) translateX(70px) translateY(-50%);
}

.birdposition {
  position: absolute;
  top: calc(50% + 60px);
  left: calc(50% - 40px);
}
.birdposition:nth-of-type(0) {
  transform: translate(-192px, 25px) rotateZ(-18deg);
}
.birdposition:nth-of-type(0) .bird {
  -webkit-animation-duration: 10949ms;
  animation-duration: 10949ms;
  -webkit-animation-delay: -1138ms;
  animation-delay: -1138ms;
}
.birdposition:nth-of-type(0) .bird .wing {
  -webkit-animation-delay: -6135ms;
  animation-delay: -6135ms;
}
.birdposition:nth-of-type(1) {
  transform: translate(-4px, 41px) rotateZ(-2deg);
}
.birdposition:nth-of-type(1) .bird {
  -webkit-animation-duration: 14853ms;
  animation-duration: 14853ms;
  -webkit-animation-delay: -19793ms;
  animation-delay: -19793ms;
}
.birdposition:nth-of-type(1) .bird .wing {
  -webkit-animation-delay: -521ms;
  animation-delay: -521ms;
}
.birdposition:nth-of-type(2) {
  transform: translate(-240px, -8px) rotateZ(3deg);
}
.birdposition:nth-of-type(2) .bird {
  -webkit-animation-duration: 7223ms;
  animation-duration: 7223ms;
  -webkit-animation-delay: -18433ms;
  animation-delay: -18433ms;
}
.birdposition:nth-of-type(2) .bird .wing {
  -webkit-animation-delay: -2118ms;
  animation-delay: -2118ms;
}
.birdposition:nth-of-type(3) {
  transform: translate(-26px, -109px) rotateZ(3deg);
}
.birdposition:nth-of-type(3) .bird {
  -webkit-animation-duration: 12597ms;
  animation-duration: 12597ms;
  -webkit-animation-delay: -15015ms;
  animation-delay: -15015ms;
}
.birdposition:nth-of-type(3) .bird .wing {
  -webkit-animation-delay: -2331ms;
  animation-delay: -2331ms;
}
.birdposition:nth-of-type(4) {
  transform: translate(-150px, -113px) rotateZ(-9deg);
}
.birdposition:nth-of-type(4) .bird {
  -webkit-animation-duration: 10121ms;
  animation-duration: 10121ms;
  -webkit-animation-delay: -16445ms;
  animation-delay: -16445ms;
}
.birdposition:nth-of-type(4) .bird .wing {
  -webkit-animation-delay: -10546ms;
  animation-delay: -10546ms;
}
.birdposition:nth-of-type(5) {
  transform: translate(109px, -35px) rotateZ(1deg);
}
.birdposition:nth-of-type(5) .bird {
  -webkit-animation-duration: 13254ms;
  animation-duration: 13254ms;
  -webkit-animation-delay: -15614ms;
  animation-delay: -15614ms;
}
.birdposition:nth-of-type(5) .bird .wing {
  -webkit-animation-delay: -1706ms;
  animation-delay: -1706ms;
}
.birdposition:nth-of-type(6) {
  transform: translate(289px, -130px) rotateZ(12deg);
}
.birdposition:nth-of-type(6) .bird {
  -webkit-animation-duration: 13508ms;
  animation-duration: 13508ms;
  -webkit-animation-delay: -19347ms;
  animation-delay: -19347ms;
}
.birdposition:nth-of-type(6) .bird .wing {
  -webkit-animation-delay: -9499ms;
  animation-delay: -9499ms;
}
.birdposition:nth-of-type(7) {
  transform: translate(271px, -71px) rotateZ(14deg);
}
.birdposition:nth-of-type(7) .bird {
  -webkit-animation-duration: 13899ms;
  animation-duration: 13899ms;
  -webkit-animation-delay: -19109ms;
  animation-delay: -19109ms;
}
.birdposition:nth-of-type(7) .bird .wing {
  -webkit-animation-delay: -7966ms;
  animation-delay: -7966ms;
}
.birdposition:nth-of-type(8) {
  transform: translate(-229px, -62px) rotateZ(15deg);
}
.birdposition:nth-of-type(8) .bird {
  -webkit-animation-duration: 9599ms;
  animation-duration: 9599ms;
  -webkit-animation-delay: -5578ms;
  animation-delay: -5578ms;
}
.birdposition:nth-of-type(8) .bird .wing {
  -webkit-animation-delay: -13752ms;
  animation-delay: -13752ms;
}
.birdposition:nth-of-type(9) {
  transform: translate(-238px, -60px) rotateZ(11deg);
}
.birdposition:nth-of-type(9) .bird {
  -webkit-animation-duration: 7392ms;
  animation-duration: 7392ms;
  -webkit-animation-delay: -7470ms;
  animation-delay: -7470ms;
}
.birdposition:nth-of-type(9) .bird .wing {
  -webkit-animation-delay: -12513ms;
  animation-delay: -12513ms;
}
.birdposition:nth-of-type(10) {
  transform: translate(60px, 27px) rotateZ(16deg);
}
.birdposition:nth-of-type(10) .bird {
  -webkit-animation-duration: 12525ms;
  animation-duration: 12525ms;
  -webkit-animation-delay: -619ms;
  animation-delay: -619ms;
}
.birdposition:nth-of-type(10) .bird .wing {
  -webkit-animation-delay: -14708ms;
  animation-delay: -14708ms;
}
.birdposition:nth-of-type(11) {
  transform: translate(-159px, -19px) rotateZ(0deg);
}
.birdposition:nth-of-type(11) .bird {
  -webkit-animation-duration: 13402ms;
  animation-duration: 13402ms;
  -webkit-animation-delay: -12453ms;
  animation-delay: -12453ms;
}
.birdposition:nth-of-type(11) .bird .wing {
  -webkit-animation-delay: -5806ms;
  animation-delay: -5806ms;
}
.birdposition:nth-of-type(12) {
  transform: translate(-49px, -93px) rotateZ(-15deg);
}
.birdposition:nth-of-type(12) .bird {
  -webkit-animation-duration: 10364ms;
  animation-duration: 10364ms;
  -webkit-animation-delay: -18591ms;
  animation-delay: -18591ms;
}
.birdposition:nth-of-type(12) .bird .wing {
  -webkit-animation-delay: -15898ms;
  animation-delay: -15898ms;
}
.birdposition:nth-of-type(13) {
  transform: translate(43px, -14px) rotateZ(-8deg);
}
.birdposition:nth-of-type(13) .bird {
  -webkit-animation-duration: 10320ms;
  animation-duration: 10320ms;
  -webkit-animation-delay: -10772ms;
  animation-delay: -10772ms;
}
.birdposition:nth-of-type(13) .bird .wing {
  -webkit-animation-delay: -15949ms;
  animation-delay: -15949ms;
}
.birdposition:nth-of-type(14) {
  transform: translate(-122px, -5px) rotateZ(12deg);
}
.birdposition:nth-of-type(14) .bird {
  -webkit-animation-duration: 10691ms;
  animation-duration: 10691ms;
  -webkit-animation-delay: -302ms;
  animation-delay: -302ms;
}
.birdposition:nth-of-type(14) .bird .wing {
  -webkit-animation-delay: -6927ms;
  animation-delay: -6927ms;
}
.birdposition:nth-of-type(15) {
  transform: translate(196px, -32px) rotateZ(-7deg);
}
.birdposition:nth-of-type(15) .bird {
  -webkit-animation-duration: 6421ms;
  animation-duration: 6421ms;
  -webkit-animation-delay: -5461ms;
  animation-delay: -5461ms;
}
.birdposition:nth-of-type(15) .bird .wing {
  -webkit-animation-delay: -5422ms;
  animation-delay: -5422ms;
}
.birdposition:nth-of-type(16) {
  transform: translate(257px, -41px) rotateZ(2deg);
}
.birdposition:nth-of-type(16) .bird {
  -webkit-animation-duration: 10797ms;
  animation-duration: 10797ms;
  -webkit-animation-delay: -4512ms;
  animation-delay: -4512ms;
}
.birdposition:nth-of-type(16) .bird .wing {
  -webkit-animation-delay: -11589ms;
  animation-delay: -11589ms;
}
.birdposition:nth-of-type(17) {
  transform: translate(248px, -33px) rotateZ(-17deg);
}
.birdposition:nth-of-type(17) .bird {
  -webkit-animation-duration: 6538ms;
  animation-duration: 6538ms;
  -webkit-animation-delay: -15030ms;
  animation-delay: -15030ms;
}
.birdposition:nth-of-type(17) .bird .wing {
  -webkit-animation-delay: -4053ms;
  animation-delay: -4053ms;
}
.birdposition:nth-of-type(18) {
  transform: translate(-152px, -17px) rotateZ(-5deg);
}
.birdposition:nth-of-type(18) .bird {
  -webkit-animation-duration: 7905ms;
  animation-duration: 7905ms;
  -webkit-animation-delay: -8988ms;
  animation-delay: -8988ms;
}
.birdposition:nth-of-type(18) .bird .wing {
  -webkit-animation-delay: -5367ms;
  animation-delay: -5367ms;
}
.birdposition:nth-of-type(19) {
  transform: translate(-96px, -87px) rotateZ(-12deg);
}
.birdposition:nth-of-type(19) .bird {
  -webkit-animation-duration: 12976ms;
  animation-duration: 12976ms;
  -webkit-animation-delay: -4903ms;
  animation-delay: -4903ms;
}
.birdposition:nth-of-type(19) .bird .wing {
  -webkit-animation-delay: -5480ms;
  animation-delay: -5480ms;
}
.birdposition:nth-of-type(20) {
  transform: translate(246px, 34px) rotateZ(10deg);
}
.birdposition:nth-of-type(20) .bird {
  -webkit-animation-duration: 9602ms;
  animation-duration: 9602ms;
  -webkit-animation-delay: -17355ms;
  animation-delay: -17355ms;
}
.birdposition:nth-of-type(20) .bird .wing {
  -webkit-animation-delay: -19563ms;
  animation-delay: -19563ms;
}
.birdposition:nth-of-type(21) {
  transform: translate(-254px, -19px) rotateZ(1deg);
}
.birdposition:nth-of-type(21) .bird {
  -webkit-animation-duration: 8703ms;
  animation-duration: 8703ms;
  -webkit-animation-delay: -11296ms;
  animation-delay: -11296ms;
}
.birdposition:nth-of-type(21) .bird .wing {
  -webkit-animation-delay: -5664ms;
  animation-delay: -5664ms;
}
.birdposition:nth-of-type(22) {
  transform: translate(-139px, 7px) rotateZ(-3deg);
}
.birdposition:nth-of-type(22) .bird {
  -webkit-animation-duration: 7441ms;
  animation-duration: 7441ms;
  -webkit-animation-delay: -6061ms;
  animation-delay: -6061ms;
}
.birdposition:nth-of-type(22) .bird .wing {
  -webkit-animation-delay: -1902ms;
  animation-delay: -1902ms;
}
.birdposition:nth-of-type(23) {
  transform: translate(-231px, -92px) rotateZ(0deg);
}
.birdposition:nth-of-type(23) .bird {
  -webkit-animation-duration: 7465ms;
  animation-duration: 7465ms;
  -webkit-animation-delay: -4691ms;
  animation-delay: -4691ms;
}
.birdposition:nth-of-type(23) .bird .wing {
  -webkit-animation-delay: -8702ms;
  animation-delay: -8702ms;
}
.birdposition:nth-of-type(24) {
  transform: translate(-88px, 11px) rotateZ(16deg);
}
.birdposition:nth-of-type(24) .bird {
  -webkit-animation-duration: 14813ms;
  animation-duration: 14813ms;
  -webkit-animation-delay: -1021ms;
  animation-delay: -1021ms;
}
.birdposition:nth-of-type(24) .bird .wing {
  -webkit-animation-delay: -2711ms;
  animation-delay: -2711ms;
}
.birdposition:nth-of-type(25) {
  transform: translate(242px, -110px) rotateZ(-3deg);
}
.birdposition:nth-of-type(25) .bird {
  -webkit-animation-duration: 11447ms;
  animation-duration: 11447ms;
  -webkit-animation-delay: -12607ms;
  animation-delay: -12607ms;
}
.birdposition:nth-of-type(25) .bird .wing {
  -webkit-animation-delay: -3533ms;
  animation-delay: -3533ms;
}
.birdposition:nth-of-type(26) {
  transform: translate(290px, -69px) rotateZ(-19deg);
}
.birdposition:nth-of-type(26) .bird {
  -webkit-animation-duration: 11276ms;
  animation-duration: 11276ms;
  -webkit-animation-delay: -4140ms;
  animation-delay: -4140ms;
}
.birdposition:nth-of-type(26) .bird .wing {
  -webkit-animation-delay: -5257ms;
  animation-delay: -5257ms;
}
.birdposition:nth-of-type(27) {
  transform: translate(225px, -46px) rotateZ(0deg);
}
.birdposition:nth-of-type(27) .bird {
  -webkit-animation-duration: 11977ms;
  animation-duration: 11977ms;
  -webkit-animation-delay: -11963ms;
  animation-delay: -11963ms;
}
.birdposition:nth-of-type(27) .bird .wing {
  -webkit-animation-delay: -10261ms;
  animation-delay: -10261ms;
}
.birdposition:nth-of-type(28) {
  transform: translate(-129px, -53px) rotateZ(18deg);
}
.birdposition:nth-of-type(28) .bird {
  -webkit-animation-duration: 13643ms;
  animation-duration: 13643ms;
  -webkit-animation-delay: -13777ms;
  animation-delay: -13777ms;
}
.birdposition:nth-of-type(28) .bird .wing {
  -webkit-animation-delay: -7813ms;
  animation-delay: -7813ms;
}
.birdposition:nth-of-type(29) {
  transform: translate(-132px, 3px) rotateZ(15deg);
}
.birdposition:nth-of-type(29) .bird {
  -webkit-animation-duration: 13347ms;
  animation-duration: 13347ms;
  -webkit-animation-delay: -15095ms;
  animation-delay: -15095ms;
}
.birdposition:nth-of-type(29) .bird .wing {
  -webkit-animation-delay: -2464ms;
  animation-delay: -2464ms;
}
.birdposition:nth-of-type(30) {
  transform: translate(-183px, 37px) rotateZ(2deg);
}
.birdposition:nth-of-type(30) .bird {
  -webkit-animation-duration: 10850ms;
  animation-duration: 10850ms;
  -webkit-animation-delay: -16275ms;
  animation-delay: -16275ms;
}
.birdposition:nth-of-type(30) .bird .wing {
  -webkit-animation-delay: -7309ms;
  animation-delay: -7309ms;
}
.birdposition:nth-of-type(31) {
  transform: translate(225px, 48px) rotateZ(18deg);
}
.birdposition:nth-of-type(31) .bird {
  -webkit-animation-duration: 10541ms;
  animation-duration: 10541ms;
  -webkit-animation-delay: -14449ms;
  animation-delay: -14449ms;
}
.birdposition:nth-of-type(31) .bird .wing {
  -webkit-animation-delay: -4704ms;
  animation-delay: -4704ms;
}
.birdposition:nth-of-type(32) {
  transform: translate(130px, -139px) rotateZ(-5deg);
}
.birdposition:nth-of-type(32) .bird {
  -webkit-animation-duration: 13386ms;
  animation-duration: 13386ms;
  -webkit-animation-delay: -2750ms;
  animation-delay: -2750ms;
}
.birdposition:nth-of-type(32) .bird .wing {
  -webkit-animation-delay: -13031ms;
  animation-delay: -13031ms;
}
.birdposition:nth-of-type(33) {
  transform: translate(-17px, -4px) rotateZ(1deg);
}
.birdposition:nth-of-type(33) .bird {
  -webkit-animation-duration: 8018ms;
  animation-duration: 8018ms;
  -webkit-animation-delay: -8966ms;
  animation-delay: -8966ms;
}
.birdposition:nth-of-type(33) .bird .wing {
  -webkit-animation-delay: -1541ms;
  animation-delay: -1541ms;
}
.birdposition:nth-of-type(34) {
  transform: translate(-91px, 20px) rotateZ(-12deg);
}
.birdposition:nth-of-type(34) .bird {
  -webkit-animation-duration: 13316ms;
  animation-duration: 13316ms;
  -webkit-animation-delay: -19718ms;
  animation-delay: -19718ms;
}
.birdposition:nth-of-type(34) .bird .wing {
  -webkit-animation-delay: -19037ms;
  animation-delay: -19037ms;
}
.birdposition:nth-of-type(35) {
  transform: translate(-74px, -124px) rotateZ(-3deg);
}
.birdposition:nth-of-type(35) .bird {
  -webkit-animation-duration: 7813ms;
  animation-duration: 7813ms;
  -webkit-animation-delay: -6437ms;
  animation-delay: -6437ms;
}
.birdposition:nth-of-type(35) .bird .wing {
  -webkit-animation-delay: -16601ms;
  animation-delay: -16601ms;
}
.birdposition:nth-of-type(36) {
  transform: translate(274px, -125px) rotateZ(-10deg);
}
.birdposition:nth-of-type(36) .bird {
  -webkit-animation-duration: 5405ms;
  animation-duration: 5405ms;
  -webkit-animation-delay: -7878ms;
  animation-delay: -7878ms;
}
.birdposition:nth-of-type(36) .bird .wing {
  -webkit-animation-delay: -16217ms;
  animation-delay: -16217ms;
}
.birdposition:nth-of-type(37) {
  transform: translate(-182px, -118px) rotateZ(5deg);
}
.birdposition:nth-of-type(37) .bird {
  -webkit-animation-duration: 9849ms;
  animation-duration: 9849ms;
  -webkit-animation-delay: -18467ms;
  animation-delay: -18467ms;
}
.birdposition:nth-of-type(37) .bird .wing {
  -webkit-animation-delay: -11380ms;
  animation-delay: -11380ms;
}
.birdposition:nth-of-type(38) {
  transform: translate(120px, -5px) rotateZ(-15deg);
}
.birdposition:nth-of-type(38) .bird {
  -webkit-animation-duration: 5932ms;
  animation-duration: 5932ms;
  -webkit-animation-delay: -4435ms;
  animation-delay: -4435ms;
}
.birdposition:nth-of-type(38) .bird .wing {
  -webkit-animation-delay: -9394ms;
  animation-delay: -9394ms;
}
.birdposition:nth-of-type(39) {
  transform: translate(63px, -93px) rotateZ(1deg);
}
.birdposition:nth-of-type(39) .bird {
  -webkit-animation-duration: 12684ms;
  animation-duration: 12684ms;
  -webkit-animation-delay: -1391ms;
  animation-delay: -1391ms;
}
.birdposition:nth-of-type(39) .bird .wing {
  -webkit-animation-delay: -6125ms;
  animation-delay: -6125ms;
}
.birdposition:nth-of-type(40) {
  transform: translate(211px, 42px) rotateZ(-16deg);
}
.birdposition:nth-of-type(40) .bird {
  -webkit-animation-duration: 12467ms;
  animation-duration: 12467ms;
  -webkit-animation-delay: -14042ms;
  animation-delay: -14042ms;
}
.birdposition:nth-of-type(40) .bird .wing {
  -webkit-animation-delay: -6433ms;
  animation-delay: -6433ms;
}
.birdposition:nth-of-type(41) {
  transform: translate(1px, -8px) rotateZ(-2deg);
}
.birdposition:nth-of-type(41) .bird {
  -webkit-animation-duration: 6251ms;
  animation-duration: 6251ms;
  -webkit-animation-delay: -6773ms;
  animation-delay: -6773ms;
}
.birdposition:nth-of-type(41) .bird .wing {
  -webkit-animation-delay: -8218ms;
  animation-delay: -8218ms;
}
.birdposition:nth-of-type(42) {
  transform: translate(86px, -134px) rotateZ(7deg);
}
.birdposition:nth-of-type(42) .bird {
  -webkit-animation-duration: 8168ms;
  animation-duration: 8168ms;
  -webkit-animation-delay: -11562ms;
  animation-delay: -11562ms;
}
.birdposition:nth-of-type(42) .bird .wing {
  -webkit-animation-delay: -1925ms;
  animation-delay: -1925ms;
}
.birdposition:nth-of-type(43) {
  transform: translate(-194px, -70px) rotateZ(-4deg);
}
.birdposition:nth-of-type(43) .bird {
  -webkit-animation-duration: 12399ms;
  animation-duration: 12399ms;
  -webkit-animation-delay: -11363ms;
  animation-delay: -11363ms;
}
.birdposition:nth-of-type(43) .bird .wing {
  -webkit-animation-delay: -2492ms;
  animation-delay: -2492ms;
}
.birdposition:nth-of-type(44) {
  transform: translate(-68px, -56px) rotateZ(0deg);
}
.birdposition:nth-of-type(44) .bird {
  -webkit-animation-duration: 8733ms;
  animation-duration: 8733ms;
  -webkit-animation-delay: -13751ms;
  animation-delay: -13751ms;
}
.birdposition:nth-of-type(44) .bird .wing {
  -webkit-animation-delay: -5770ms;
  animation-delay: -5770ms;
}
.birdposition:nth-of-type(45) {
  transform: translate(224px, -105px) rotateZ(-4deg);
}
.birdposition:nth-of-type(45) .bird {
  -webkit-animation-duration: 13863ms;
  animation-duration: 13863ms;
  -webkit-animation-delay: -14312ms;
  animation-delay: -14312ms;
}
.birdposition:nth-of-type(45) .bird .wing {
  -webkit-animation-delay: -12216ms;
  animation-delay: -12216ms;
}
.birdposition:nth-of-type(46) {
  transform: translate(124px, -44px) rotateZ(14deg);
}
.birdposition:nth-of-type(46) .bird {
  -webkit-animation-duration: 11356ms;
  animation-duration: 11356ms;
  -webkit-animation-delay: -18927ms;
  animation-delay: -18927ms;
}
.birdposition:nth-of-type(46) .bird .wing {
  -webkit-animation-delay: -19366ms;
  animation-delay: -19366ms;
}
.birdposition:nth-of-type(47) {
  transform: translate(23px, -102px) rotateZ(1deg);
}
.birdposition:nth-of-type(47) .bird {
  -webkit-animation-duration: 5363ms;
  animation-duration: 5363ms;
  -webkit-animation-delay: -7429ms;
  animation-delay: -7429ms;
}
.birdposition:nth-of-type(47) .bird .wing {
  -webkit-animation-delay: -12050ms;
  animation-delay: -12050ms;
}
.birdposition:nth-of-type(48) {
  transform: translate(-183px, -27px) rotateZ(-13deg);
}
.birdposition:nth-of-type(48) .bird {
  -webkit-animation-duration: 6283ms;
  animation-duration: 6283ms;
  -webkit-animation-delay: -1838ms;
  animation-delay: -1838ms;
}
.birdposition:nth-of-type(48) .bird .wing {
  -webkit-animation-delay: -4254ms;
  animation-delay: -4254ms;
}
.birdposition:nth-of-type(49) {
  transform: translate(293px, 36px) rotateZ(14deg);
}
.birdposition:nth-of-type(49) .bird {
  -webkit-animation-duration: 6757ms;
  animation-duration: 6757ms;
  -webkit-animation-delay: -2036ms;
  animation-delay: -2036ms;
}
.birdposition:nth-of-type(49) .bird .wing {
  -webkit-animation-delay: -13076ms;
  animation-delay: -13076ms;
}
.birdposition:nth-of-type(50) {
  transform: translate(-79px, -92px) rotateZ(13deg);
}
.birdposition:nth-of-type(50) .bird {
  -webkit-animation-duration: 8587ms;
  animation-duration: 8587ms;
  -webkit-animation-delay: -1664ms;
  animation-delay: -1664ms;
}
.birdposition:nth-of-type(50) .bird .wing {
  -webkit-animation-delay: -4390ms;
  animation-delay: -4390ms;
}
.birdposition:nth-of-type(51) {
  transform: translate(274px, 26px) rotateZ(-17deg);
}
.birdposition:nth-of-type(51) .bird {
  -webkit-animation-duration: 9761ms;
  animation-duration: 9761ms;
  -webkit-animation-delay: -11099ms;
  animation-delay: -11099ms;
}
.birdposition:nth-of-type(51) .bird .wing {
  -webkit-animation-delay: -1511ms;
  animation-delay: -1511ms;
}
.birdposition:nth-of-type(52) {
  transform: translate(-141px, -70px) rotateZ(6deg);
}
.birdposition:nth-of-type(52) .bird {
  -webkit-animation-duration: 14561ms;
  animation-duration: 14561ms;
  -webkit-animation-delay: -13864ms;
  animation-delay: -13864ms;
}
.birdposition:nth-of-type(52) .bird .wing {
  -webkit-animation-delay: -2445ms;
  animation-delay: -2445ms;
}
.birdposition:nth-of-type(53) {
  transform: translate(-24px, -78px) rotateZ(-11deg);
}
.birdposition:nth-of-type(53) .bird {
  -webkit-animation-duration: 10059ms;
  animation-duration: 10059ms;
  -webkit-animation-delay: -8557ms;
  animation-delay: -8557ms;
}
.birdposition:nth-of-type(53) .bird .wing {
  -webkit-animation-delay: -14663ms;
  animation-delay: -14663ms;
}
.birdposition:nth-of-type(54) {
  transform: translate(80px, -35px) rotateZ(-14deg);
}
.birdposition:nth-of-type(54) .bird {
  -webkit-animation-duration: 8848ms;
  animation-duration: 8848ms;
  -webkit-animation-delay: -19486ms;
  animation-delay: -19486ms;
}
.birdposition:nth-of-type(54) .bird .wing {
  -webkit-animation-delay: -10646ms;
  animation-delay: -10646ms;
}
.birdposition:nth-of-type(55) {
  transform: translate(-39px, -124px) rotateZ(5deg);
}
.birdposition:nth-of-type(55) .bird {
  -webkit-animation-duration: 10056ms;
  animation-duration: 10056ms;
  -webkit-animation-delay: -7853ms;
  animation-delay: -7853ms;
}
.birdposition:nth-of-type(55) .bird .wing {
  -webkit-animation-delay: -6690ms;
  animation-delay: -6690ms;
}
.birdposition:nth-of-type(56) {
  transform: translate(58px, 35px) rotateZ(19deg);
}
.birdposition:nth-of-type(56) .bird {
  -webkit-animation-duration: 9518ms;
  animation-duration: 9518ms;
  -webkit-animation-delay: -2547ms;
  animation-delay: -2547ms;
}
.birdposition:nth-of-type(56) .bird .wing {
  -webkit-animation-delay: -7007ms;
  animation-delay: -7007ms;
}
.birdposition:nth-of-type(57) {
  transform: translate(79px, -101px) rotateZ(8deg);
}
.birdposition:nth-of-type(57) .bird {
  -webkit-animation-duration: 9648ms;
  animation-duration: 9648ms;
  -webkit-animation-delay: -4659ms;
  animation-delay: -4659ms;
}
.birdposition:nth-of-type(57) .bird .wing {
  -webkit-animation-delay: -16530ms;
  animation-delay: -16530ms;
}
.birdposition:nth-of-type(58) {
  transform: translate(-54px, 43px) rotateZ(-19deg);
}
.birdposition:nth-of-type(58) .bird {
  -webkit-animation-duration: 10634ms;
  animation-duration: 10634ms;
  -webkit-animation-delay: -12237ms;
  animation-delay: -12237ms;
}
.birdposition:nth-of-type(58) .bird .wing {
  -webkit-animation-delay: -10946ms;
  animation-delay: -10946ms;
}
.birdposition:nth-of-type(59) {
  transform: translate(53px, 10px) rotateZ(19deg);
}
.birdposition:nth-of-type(59) .bird {
  -webkit-animation-duration: 12399ms;
  animation-duration: 12399ms;
  -webkit-animation-delay: -8130ms;
  animation-delay: -8130ms;
}
.birdposition:nth-of-type(59) .bird .wing {
  -webkit-animation-delay: -13058ms;
  animation-delay: -13058ms;
}
.birdposition:nth-of-type(60) {
  transform: translate(-283px, -30px) rotateZ(2deg);
}
.birdposition:nth-of-type(60) .bird {
  -webkit-animation-duration: 13469ms;
  animation-duration: 13469ms;
  -webkit-animation-delay: -14644ms;
  animation-delay: -14644ms;
}
.birdposition:nth-of-type(60) .bird .wing {
  -webkit-animation-delay: -8636ms;
  animation-delay: -8636ms;
}
.birdposition:nth-of-type(61) {
  transform: translate(184px, 9px) rotateZ(-6deg);
}
.birdposition:nth-of-type(61) .bird {
  -webkit-animation-duration: 10879ms;
  animation-duration: 10879ms;
  -webkit-animation-delay: -10434ms;
  animation-delay: -10434ms;
}
.birdposition:nth-of-type(61) .bird .wing {
  -webkit-animation-delay: -10558ms;
  animation-delay: -10558ms;
}
.birdposition:nth-of-type(62) {
  transform: translate(276px, 4px) rotateZ(3deg);
}
.birdposition:nth-of-type(62) .bird {
  -webkit-animation-duration: 7971ms;
  animation-duration: 7971ms;
  -webkit-animation-delay: -6324ms;
  animation-delay: -6324ms;
}
.birdposition:nth-of-type(62) .bird .wing {
  -webkit-animation-delay: -18569ms;
  animation-delay: -18569ms;
}
.birdposition:nth-of-type(63) {
  transform: translate(18px, -60px) rotateZ(14deg);
}
.birdposition:nth-of-type(63) .bird {
  -webkit-animation-duration: 5529ms;
  animation-duration: 5529ms;
  -webkit-animation-delay: -16357ms;
  animation-delay: -16357ms;
}
.birdposition:nth-of-type(63) .bird .wing {
  -webkit-animation-delay: -1956ms;
  animation-delay: -1956ms;
}
.birdposition:nth-of-type(64) {
  transform: translate(272px, -78px) rotateZ(18deg);
}
.birdposition:nth-of-type(64) .bird {
  -webkit-animation-duration: 14076ms;
  animation-duration: 14076ms;
  -webkit-animation-delay: -16263ms;
  animation-delay: -16263ms;
}
.birdposition:nth-of-type(64) .bird .wing {
  -webkit-animation-delay: -9120ms;
  animation-delay: -9120ms;
}
.birdposition:nth-of-type(65) {
  transform: translate(206px, -80px) rotateZ(-10deg);
}
.birdposition:nth-of-type(65) .bird {
  -webkit-animation-duration: 6800ms;
  animation-duration: 6800ms;
  -webkit-animation-delay: -15850ms;
  animation-delay: -15850ms;
}
.birdposition:nth-of-type(65) .bird .wing {
  -webkit-animation-delay: -19793ms;
  animation-delay: -19793ms;
}
.birdposition:nth-of-type(66) {
  transform: translate(281px, -24px) rotateZ(-1deg);
}
.birdposition:nth-of-type(66) .bird {
  -webkit-animation-duration: 6781ms;
  animation-duration: 6781ms;
  -webkit-animation-delay: -18623ms;
  animation-delay: -18623ms;
}
.birdposition:nth-of-type(66) .bird .wing {
  -webkit-animation-delay: -2175ms;
  animation-delay: -2175ms;
}
.birdposition:nth-of-type(67) {
  transform: translate(-14px, -133px) rotateZ(3deg);
}
.birdposition:nth-of-type(67) .bird {
  -webkit-animation-duration: 6727ms;
  animation-duration: 6727ms;
  -webkit-animation-delay: -2203ms;
  animation-delay: -2203ms;
}
.birdposition:nth-of-type(67) .bird .wing {
  -webkit-animation-delay: -6310ms;
  animation-delay: -6310ms;
}
.birdposition:nth-of-type(68) {
  transform: translate(-128px, -48px) rotateZ(-12deg);
}
.birdposition:nth-of-type(68) .bird {
  -webkit-animation-duration: 12516ms;
  animation-duration: 12516ms;
  -webkit-animation-delay: -12832ms;
  animation-delay: -12832ms;
}
.birdposition:nth-of-type(68) .bird .wing {
  -webkit-animation-delay: -11841ms;
  animation-delay: -11841ms;
}
.birdposition:nth-of-type(69) {
  transform: translate(-38px, -40px) rotateZ(12deg);
}
.birdposition:nth-of-type(69) .bird {
  -webkit-animation-duration: 7828ms;
  animation-duration: 7828ms;
  -webkit-animation-delay: -19915ms;
  animation-delay: -19915ms;
}
.birdposition:nth-of-type(69) .bird .wing {
  -webkit-animation-delay: -12194ms;
  animation-delay: -12194ms;
}
.birdposition:nth-of-type(70) {
  transform: translate(-107px, 1px) rotateZ(0deg);
}
.birdposition:nth-of-type(70) .bird {
  -webkit-animation-duration: 12389ms;
  animation-duration: 12389ms;
  -webkit-animation-delay: -1665ms;
  animation-delay: -1665ms;
}
.birdposition:nth-of-type(70) .bird .wing {
  -webkit-animation-delay: -1504ms;
  animation-delay: -1504ms;
}
.birdposition:nth-of-type(71) {
  transform: translate(143px, -60px) rotateZ(-3deg);
}
.birdposition:nth-of-type(71) .bird {
  -webkit-animation-duration: 10529ms;
  animation-duration: 10529ms;
  -webkit-animation-delay: -3369ms;
  animation-delay: -3369ms;
}
.birdposition:nth-of-type(71) .bird .wing {
  -webkit-animation-delay: -800ms;
  animation-delay: -800ms;
}
.birdposition:nth-of-type(72) {
  transform: translate(-250px, -75px) rotateZ(-6deg);
}
.birdposition:nth-of-type(72) .bird {
  -webkit-animation-duration: 10322ms;
  animation-duration: 10322ms;
  -webkit-animation-delay: -19780ms;
  animation-delay: -19780ms;
}
.birdposition:nth-of-type(72) .bird .wing {
  -webkit-animation-delay: -3931ms;
  animation-delay: -3931ms;
}
.birdposition:nth-of-type(73) {
  transform: translate(284px, 14px) rotateZ(14deg);
}
.birdposition:nth-of-type(73) .bird {
  -webkit-animation-duration: 12016ms;
  animation-duration: 12016ms;
  -webkit-animation-delay: -455ms;
  animation-delay: -455ms;
}
.birdposition:nth-of-type(73) .bird .wing {
  -webkit-animation-delay: -17134ms;
  animation-delay: -17134ms;
}
.birdposition:nth-of-type(74) {
  transform: translate(-114px, -114px) rotateZ(17deg);
}
.birdposition:nth-of-type(74) .bird {
  -webkit-animation-duration: 13438ms;
  animation-duration: 13438ms;
  -webkit-animation-delay: -7177ms;
  animation-delay: -7177ms;
}
.birdposition:nth-of-type(74) .bird .wing {
  -webkit-animation-delay: -3386ms;
  animation-delay: -3386ms;
}
.birdposition:nth-of-type(75) {
  transform: translate(-15px, -30px) rotateZ(-19deg);
}
.birdposition:nth-of-type(75) .bird {
  -webkit-animation-duration: 11423ms;
  animation-duration: 11423ms;
  -webkit-animation-delay: -13272ms;
  animation-delay: -13272ms;
}
.birdposition:nth-of-type(75) .bird .wing {
  -webkit-animation-delay: -710ms;
  animation-delay: -710ms;
}
.birdposition:nth-of-type(76) {
  transform: translate(-244px, -126px) rotateZ(15deg);
}
.birdposition:nth-of-type(76) .bird {
  -webkit-animation-duration: 5304ms;
  animation-duration: 5304ms;
  -webkit-animation-delay: -8586ms;
  animation-delay: -8586ms;
}
.birdposition:nth-of-type(76) .bird .wing {
  -webkit-animation-delay: -17074ms;
  animation-delay: -17074ms;
}
.birdposition:nth-of-type(77) {
  transform: translate(91px, 24px) rotateZ(-18deg);
}
.birdposition:nth-of-type(77) .bird {
  -webkit-animation-duration: 9064ms;
  animation-duration: 9064ms;
  -webkit-animation-delay: -6177ms;
  animation-delay: -6177ms;
}
.birdposition:nth-of-type(77) .bird .wing {
  -webkit-animation-delay: -4308ms;
  animation-delay: -4308ms;
}
.birdposition:nth-of-type(78) {
  transform: translate(43px, -20px) rotateZ(-9deg);
}
.birdposition:nth-of-type(78) .bird {
  -webkit-animation-duration: 9933ms;
  animation-duration: 9933ms;
  -webkit-animation-delay: -2733ms;
  animation-delay: -2733ms;
}
.birdposition:nth-of-type(78) .bird .wing {
  -webkit-animation-delay: -5965ms;
  animation-delay: -5965ms;
}
.birdposition:nth-of-type(79) {
  transform: translate(46px, -115px) rotateZ(-18deg);
}
.birdposition:nth-of-type(79) .bird {
  -webkit-animation-duration: 10621ms;
  animation-duration: 10621ms;
  -webkit-animation-delay: -9588ms;
  animation-delay: -9588ms;
}
.birdposition:nth-of-type(79) .bird .wing {
  -webkit-animation-delay: -12331ms;
  animation-delay: -12331ms;
}
.birdposition:nth-of-type(80) {
  transform: translate(149px, -123px) rotateZ(2deg);
}
.birdposition:nth-of-type(80) .bird {
  -webkit-animation-duration: 13440ms;
  animation-duration: 13440ms;
  -webkit-animation-delay: -3894ms;
  animation-delay: -3894ms;
}
.birdposition:nth-of-type(80) .bird .wing {
  -webkit-animation-delay: -13411ms;
  animation-delay: -13411ms;
}
.birdposition:nth-of-type(81) {
  transform: translate(139px, -149px) rotateZ(11deg);
}
.birdposition:nth-of-type(81) .bird {
  -webkit-animation-duration: 5073ms;
  animation-duration: 5073ms;
  -webkit-animation-delay: -14028ms;
  animation-delay: -14028ms;
}
.birdposition:nth-of-type(81) .bird .wing {
  -webkit-animation-delay: -13091ms;
  animation-delay: -13091ms;
}
.birdposition:nth-of-type(82) {
  transform: translate(150px, 9px) rotateZ(-9deg);
}
.birdposition:nth-of-type(82) .bird {
  -webkit-animation-duration: 9782ms;
  animation-duration: 9782ms;
  -webkit-animation-delay: -12556ms;
  animation-delay: -12556ms;
}
.birdposition:nth-of-type(82) .bird .wing {
  -webkit-animation-delay: -6366ms;
  animation-delay: -6366ms;
}
.birdposition:nth-of-type(83) {
  transform: translate(226px, -21px) rotateZ(-12deg);
}
.birdposition:nth-of-type(83) .bird {
  -webkit-animation-duration: 13174ms;
  animation-duration: 13174ms;
  -webkit-animation-delay: -19266ms;
  animation-delay: -19266ms;
}
.birdposition:nth-of-type(83) .bird .wing {
  -webkit-animation-delay: -9300ms;
  animation-delay: -9300ms;
}
.birdposition:nth-of-type(84) {
  transform: translate(-208px, -87px) rotateZ(18deg);
}
.birdposition:nth-of-type(84) .bird {
  -webkit-animation-duration: 11625ms;
  animation-duration: 11625ms;
  -webkit-animation-delay: -3688ms;
  animation-delay: -3688ms;
}
.birdposition:nth-of-type(84) .bird .wing {
  -webkit-animation-delay: -17642ms;
  animation-delay: -17642ms;
}
.birdposition:nth-of-type(85) {
  transform: translate(-162px, -96px) rotateZ(-3deg);
}
.birdposition:nth-of-type(85) .bird {
  -webkit-animation-duration: 14539ms;
  animation-duration: 14539ms;
  -webkit-animation-delay: -19722ms;
  animation-delay: -19722ms;
}
.birdposition:nth-of-type(85) .bird .wing {
  -webkit-animation-delay: -18255ms;
  animation-delay: -18255ms;
}
.birdposition:nth-of-type(86) {
  transform: translate(-6px, 11px) rotateZ(-5deg);
}
.birdposition:nth-of-type(86) .bird {
  -webkit-animation-duration: 11913ms;
  animation-duration: 11913ms;
  -webkit-animation-delay: -2794ms;
  animation-delay: -2794ms;
}
.birdposition:nth-of-type(86) .bird .wing {
  -webkit-animation-delay: -903ms;
  animation-delay: -903ms;
}
.birdposition:nth-of-type(87) {
  transform: translate(-134px, -70px) rotateZ(1deg);
}
.birdposition:nth-of-type(87) .bird {
  -webkit-animation-duration: 13760ms;
  animation-duration: 13760ms;
  -webkit-animation-delay: -16667ms;
  animation-delay: -16667ms;
}
.birdposition:nth-of-type(87) .bird .wing {
  -webkit-animation-delay: -316ms;
  animation-delay: -316ms;
}
.birdposition:nth-of-type(88) {
  transform: translate(-40px, -50px) rotateZ(-5deg);
}
.birdposition:nth-of-type(88) .bird {
  -webkit-animation-duration: 8726ms;
  animation-duration: 8726ms;
  -webkit-animation-delay: -18573ms;
  animation-delay: -18573ms;
}
.birdposition:nth-of-type(88) .bird .wing {
  -webkit-animation-delay: -10221ms;
  animation-delay: -10221ms;
}
.birdposition:nth-of-type(89) {
  transform: translate(-245px, -59px) rotateZ(16deg);
}
.birdposition:nth-of-type(89) .bird {
  -webkit-animation-duration: 6099ms;
  animation-duration: 6099ms;
  -webkit-animation-delay: -16165ms;
  animation-delay: -16165ms;
}
.birdposition:nth-of-type(89) .bird .wing {
  -webkit-animation-delay: -1298ms;
  animation-delay: -1298ms;
}
.birdposition:nth-of-type(90) {
  transform: translate(-276px, -56px) rotateZ(13deg);
}
.birdposition:nth-of-type(90) .bird {
  -webkit-animation-duration: 11493ms;
  animation-duration: 11493ms;
  -webkit-animation-delay: -10645ms;
  animation-delay: -10645ms;
}
.birdposition:nth-of-type(90) .bird .wing {
  -webkit-animation-delay: -2287ms;
  animation-delay: -2287ms;
}
.birdposition:nth-of-type(91) {
  transform: translate(107px, 23px) rotateZ(-4deg);
}
.birdposition:nth-of-type(91) .bird {
  -webkit-animation-duration: 12425ms;
  animation-duration: 12425ms;
  -webkit-animation-delay: -10258ms;
  animation-delay: -10258ms;
}
.birdposition:nth-of-type(91) .bird .wing {
  -webkit-animation-delay: -19032ms;
  animation-delay: -19032ms;
}
.birdposition:nth-of-type(92) {
  transform: translate(87px, -11px) rotateZ(-5deg);
}
.birdposition:nth-of-type(92) .bird {
  -webkit-animation-duration: 8222ms;
  animation-duration: 8222ms;
  -webkit-animation-delay: -6841ms;
  animation-delay: -6841ms;
}
.birdposition:nth-of-type(92) .bird .wing {
  -webkit-animation-delay: -6871ms;
  animation-delay: -6871ms;
}
.birdposition:nth-of-type(93) {
  transform: translate(-10px, -55px) rotateZ(0deg);
}
.birdposition:nth-of-type(93) .bird {
  -webkit-animation-duration: 9156ms;
  animation-duration: 9156ms;
  -webkit-animation-delay: -1882ms;
  animation-delay: -1882ms;
}
.birdposition:nth-of-type(93) .bird .wing {
  -webkit-animation-delay: -2604ms;
  animation-delay: -2604ms;
}
.birdposition:nth-of-type(94) {
  transform: translate(-36px, -68px) rotateZ(-7deg);
}
.birdposition:nth-of-type(94) .bird {
  -webkit-animation-duration: 13567ms;
  animation-duration: 13567ms;
  -webkit-animation-delay: -10792ms;
  animation-delay: -10792ms;
}
.birdposition:nth-of-type(94) .bird .wing {
  -webkit-animation-delay: -6477ms;
  animation-delay: -6477ms;
}
.birdposition:nth-of-type(95) {
  transform: translate(24px, 19px) rotateZ(0deg);
}
.birdposition:nth-of-type(95) .bird {
  -webkit-animation-duration: 8721ms;
  animation-duration: 8721ms;
  -webkit-animation-delay: -11063ms;
  animation-delay: -11063ms;
}
.birdposition:nth-of-type(95) .bird .wing {
  -webkit-animation-delay: -1239ms;
  animation-delay: -1239ms;
}
.birdposition:nth-of-type(96) {
  transform: translate(-236px, -33px) rotateZ(11deg);
}
.birdposition:nth-of-type(96) .bird {
  -webkit-animation-duration: 5626ms;
  animation-duration: 5626ms;
  -webkit-animation-delay: -19304ms;
  animation-delay: -19304ms;
}
.birdposition:nth-of-type(96) .bird .wing {
  -webkit-animation-delay: -12805ms;
  animation-delay: -12805ms;
}
.birdposition:nth-of-type(97) {
  transform: translate(-147px, -16px) rotateZ(-11deg);
}
.birdposition:nth-of-type(97) .bird {
  -webkit-animation-duration: 11762ms;
  animation-duration: 11762ms;
  -webkit-animation-delay: -19418ms;
  animation-delay: -19418ms;
}
.birdposition:nth-of-type(97) .bird .wing {
  -webkit-animation-delay: -7132ms;
  animation-delay: -7132ms;
}
.birdposition:nth-of-type(98) {
  transform: translate(270px, -114px) rotateZ(-13deg);
}
.birdposition:nth-of-type(98) .bird {
  -webkit-animation-duration: 12993ms;
  animation-duration: 12993ms;
  -webkit-animation-delay: -7323ms;
  animation-delay: -7323ms;
}
.birdposition:nth-of-type(98) .bird .wing {
  -webkit-animation-delay: -630ms;
  animation-delay: -630ms;
}
.birdposition:nth-of-type(99) {
  transform: translate(160px, 38px) rotateZ(0deg);
}
.birdposition:nth-of-type(99) .bird {
  -webkit-animation-duration: 9073ms;
  animation-duration: 9073ms;
  -webkit-animation-delay: -9567ms;
  animation-delay: -9567ms;
}
.birdposition:nth-of-type(99) .bird .wing {
  -webkit-animation-delay: -11148ms;
  animation-delay: -11148ms;
}
.birdposition:nth-of-type(100) {
  transform: translate(239px, -115px) rotateZ(14deg);
}
.birdposition:nth-of-type(100) .bird {
  -webkit-animation-duration: 6294ms;
  animation-duration: 6294ms;
  -webkit-animation-delay: -4453ms;
  animation-delay: -4453ms;
}
.birdposition:nth-of-type(100) .bird .wing {
  -webkit-animation-delay: -496ms;
  animation-delay: -496ms;
}

.bird {
  position: absolute;
  width: 80px;
  -webkit-animation: fly 8s linear infinite;
  animation: fly 8s linear infinite;
}
.bird .wing {
  position: absolute;
  width: 50%;
  height: 20px;
  border-top: 4px solid black;
  border-radius: 100%;
}
.bird .wing.-left {
  transform-origin: 100% 50%;
  -webkit-animation: wingLeft 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95)
    infinite alternate;
  animation: wingLeft 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite
    alternate;
}
.bird .wing.-right {
  right: 0;
  transform-origin: 0 50%;
  -webkit-animation: wingRight 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95)
    infinite alternate;
  animation: wingRight 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite
    alternate;
}

@-webkit-keyframes fly {
  0% {
    opacity: 0;
    transform: translateZ(500px);
  }
  20% {
    opacity: 1;
    transform: translateZ(400px);
  }
  100% {
    transform: translateZ(0px) scale(0);
  }
}

@keyframes fly {
  0% {
    opacity: 0;
    transform: translateZ(500px);
  }
  20% {
    opacity: 1;
    transform: translateZ(400px);
  }
  100% {
    transform: translateZ(0px) scale(0);
  }
}
@-webkit-keyframes wingLeft {
  0% {
    transform: rotateZ(30deg);
  }
  100% {
    transform: rotateZ(-20deg);
  }
}
@keyframes wingLeft {
  0% {
    transform: rotateZ(30deg);
  }
  100% {
    transform: rotateZ(-20deg);
  }
}
@-webkit-keyframes wingRight {
  0% {
    transform: rotateZ(-30deg);
  }
  100% {
    transform: rotateZ(20deg);
  }
}
@keyframes wingRight {
  0% {
    transform: rotateZ(-30deg);
  }
  100% {
    transform: rotateZ(20deg);
  }
}
</style>