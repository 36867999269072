<template>
  <div class="preload">
    <div class="preload-birds-box">
      <preloadbirds></preloadbirds>
    </div>
    <div class="preload-title"></div>
    <div class="preload-btn" @click="goToHome"></div>
    <div class="gallery">
      <div class="row">
        <div
          class="img-container"
          style="
            background-image: url('https://www.hereitis.cn/profile/adminUpload/2024/08/20/d043bad2e1f1466bb17f4b7cf7c020bb.png');
          "
        >
          <div
            class="img"
            style="
              background-image: url('https://www.hereitis.cn/profile/adminUpload/2024/08/20/d043bad2e1f1466bb17f4b7cf7c020bb.png');
            "
          ></div>
        </div>
      
      </div>
      <div class="row1">
        <div
          class="img-container"
          style="
            background-image: url('https://www.hereitis.cn/profile/adminUpload/2024/08/20/d043bad2e1f1466bb17f4b7cf7c020bb.png');
          "
        >
          <div
            class="img"
            style="
              background-image: url('https://www.hereitis.cn/profile/adminUpload/2024/08/20/d043bad2e1f1466bb17f4b7cf7c020bb.png');
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import preloadbirds from '@/components/preloadBirds.vue';

export default {
  name: "preload-page",
  components: {
    preloadbirds
  },
  methods: {
    goToHome() {
      // 跳转到主页
      this.$router.push("/");
    },
  },
};
</script>
  
  <style lang="scss" scoped>
  .preload-birds-box{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.preload {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-size: 24px;
  color: #000;
  /* background-color: aqua; */
  background: url("https://www.hereitis.cn/profile/adminUpload/2024/08/20/6ee4a37d7c8946c8be42e63154804f62.jpg")
    no-repeat center;
  background-size: 100% 100%;
}
.preload-title {
  text-align: center;
  width: 400px;
  height: 120px;
  margin: 0 auto;
  margin-top: 200px;
  background: url("https://www.hereitis.cn/profile/adminUpload/2024/08/20/7a1da0ba2bb74711a884bde17fda92b2.png")
    no-repeat center;
  background-size: 100% 100%;
}
.preload-btn {
  width: 166px;
  height: 45px;
  margin: 0 auto;
  margin-top: 80px;
  background: url("https://www.hereitis.cn/profile/adminUpload/2024/08/20/b1251e0825f344dba88a7b5cf8fe7628.png")
    no-repeat center;
  background-size: 100% 100%;
  z-index:999;
  
}
button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
}
.gallery {
  position: absolute;
  top: 1%;
  display: flex;
  flex-direction: column;
  width: 50vw;
  justify-content: center;
  // overflow: hidden;
}

.row, .row1 {
  display: flex;
  // animation-iteration-count: infinite;
}

.row {
  // transform: translateX(0vw);
  animation: runner 20s linear forwards;
}

.row1 {
  // transform: translateX(150vw);
  animation: runner1 20s linear forwards;
}

.img-container {
  position: relative;
  background-position: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  flex: none;
  height: 30vh;
  margin: clamp(0.625rem, 2vw, 1.25rem);
  width: 32vw;
  transition: 10s;
}

.img-container .img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: 10s;
  z-index: 2;
}

@keyframes runner {
  0% {
    transform: translateX(-50vw);
  }
  100%{
    transform: translateX(150vw);
  }
  // 100% {
  //   transform: translateX(-50vw);
  // }
}

@keyframes runner1 {
  0% {
    transform: translateX(150vw);
  }
  100% {
    transform: translateX(-50vw);
  }
  // 100% {
  //   transform: translateX(150vw);
  // }
}
</style>