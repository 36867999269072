import {
	Service 
} from "@/api/request";

// 请求的地址
// export const baseUrl = 'https://clanss.com/sanshengxun';

// 用户记录
export const userInfoAccessLogApi = (data) => {
	return Service ({
		url: `/web/basics/accessLog`,
		method: "POST",
		data
	})
};
// 登录
export const webLoginApi = (code) => {
	return Service({
		url: `/web/login/wxWebLogin`,
		method: "GET",
		params:{
			code
		}
	})
  };
// 校验登录 /web/login/tokenLogin
export const webTokenLoginApi = (token) => {
	return Service({
		url: `/web/login/wxWebLogin`,
		method: "POST",
		params:{
			token
		}
	})
  };
// 首页弹幕
export const randomUserLightenListApi = (data) => {
	return Service ({
		url: `/web/basics/randomUserLightenList`,
		method: "GET",
		data
	});
};

// 字典数据
export const dictDataApi = (dictType) => {
	return Service ({
		url: `/web/basics/dictData/${dictType}`,
		method: 'GET',
	})
}


// 列表接口
export const mapScenicDataApi = (data) => {
	return Service ({
		url: '/web/scenic/mapScenicData',
		method: 'GET',
		data
	})
}

// 筛选接口
export const filtrateOptionApi = (data) => {
	return Service ({
		url: '/web/scenic/tagOptions',
		method: 'GET',
		data
	})
}

// 景点详情
export const scenicDetailApi = (data) => {
	return Service ({
		url: '/web/scenic/scenicDetail',
		method: 'GET',
		data
	})
}

// 景点停车
export const scenicParkListApi = (data) => {
	return Service ({
		url: '/web/scenic/scenicParkList',
		method: 'GET',
		data
	})
}

// 说说接口 大家说列表
export const talkListApi = (data) => {
	return Service ({
		url: '/web/talk/list',
		method: 'GET',
		data
	})
}

// 文章接口 攻略
export const articleApi = (data) => {
	return Service ({
		url: '/web/article/list',
		method: 'GET',
		data
	})
}

// 文章接口 攻略详情界面
export const articleDetailsApi = (articleId) => {
	return Service ({
		url: `/web/article/${articleId}`,
		method: 'GET',
	})
}

// 小程序登录接口
export const wxLoginApi = (data) => {
	return Service ({
		url: '/web/login/wxAppletLogin',
		method: 'GET',
		data
	})
}

// 用户信息修改
export const userInfoFixedApi = (data) => {
	return Service ({
		url: `/web/userCenter/nickNameAvatarEdit`,
		method: "GET",
		data
	});
};

// 用户中心数据
export const userCenterDataApi = (data) => {
	return Service ({
		url: '/web/userCenter/UserCenterData',
		method: 'GET',
		data
	})
}

// 景点点亮
export const lightenApi = (scenicId) => {
	return Service ({
		url: `/web/scenic/${scenicId}/lighten`,
		method: 'POST',
	})

}

// 景点天气
export const weatherApi = (scenicId) => {
	return Service ({
		url: `/web/scenic/${scenicId}/weather`,
		method: 'GET'
	})
}


// 上传图片的接口
export const uploadImgApi = (data) => {
	return Service ({
		url: `/web/basics/upload/image`,
		method: 'POST',
		data
	})
}

// 活动列表
export const activeListApi = (data) => {
	return Service ({
		url: `/web/activity/list`,
		method: 'GET',
		data
	})
}

// 活动详情
export const activeDetalsApi = (activityId) => {
	return Service ({
		url: `/web/activity/${activityId}`,
		method: 'GET',
	})
}

// 活动详情 围观用户列表
export const activeOnlookerApi = (activityId) => {
	return Service ({
		url: `/web/activity/${activityId}/onlooker`,
		method: 'GET'
	})
}

// 活动详情  用户围观
export const selfOnlookerApi = (activityId) => {
	return Service ({
		url: `/web/activity/${activityId}/onlooker`,
		method: 'POST'
	})
}

// 发攻略
export const publishArticleApi = (data) => {
	return Service ({
		url: `/web/article/publishArticle`,
		method: 'POST',
		data
	})
}

// 发心得
export const publishTalkApi = (data) => {
	return Service ({
		url: `/web/talk/publishTalk`,
		method: 'POST',
		data
	})
}


// 义工列表
export const volunteerListApi = (params) => {
	return Service ({
		url: `/web/volunteer/volunteerActivity`,
		method: "GET",
		params,
	});
};

// 义工列表
export const volunteerDetailsApi = (volunteerActivityId) => {
	return Service ({
		url: `/web/volunteer/volunteerActivity/${volunteerActivityId}`,
		method: "GET",
	});
};

// 日历信息查询
export const todayCalendarInfoApi = (data) => {
	return Service ({
		url: `/web/basics/todayCalendarInfo`,
		method: "GET",
		data
	});
};




// 我的 - 账户设置接口
export const accountInfoEditPageDataApi = (data) => {
	return Service ({
		url: `/web/userCenter/accountInfoEditPageData`,
		method: "GET",
		data
	});
};

// 用户角色与权限
export const rolePermissionListApi = (data) => {
	return Service ({
		url: `/web/userCenter/rolePermissionList`,
		method: "GET",
		data
	});
};

// 账户信息修改
export const accountInfoEditApi = (data) => {
	return Service ({
		url: `/web/userCenter/accountInfoEdit`,
		method: "PUT",
		data
	});
};


// 管理认证信息
export const managerAuthInfoApi = (data) => {
	return Service ({
		url: `/web/scenicManage/managerAuthInfo`,
		method: "GET",
		data
	});
};

// 管理认证提交审核
export const managerAuthSubmitApi = (data) => {
	return Service ({
		url: `/web/scenicManage/managerAuthSubmit`,
		method: "POST",
		data
	});
};


// 管理认证提交修改
export const managerAuthSubmitUpdateApi = (data) => {
	return Service ({
		url: `/web/scenicManage/managerAuthSubmitUpdate`,
		method: "POST",
		data
	});
};


// 我的景点 - 列表
export const myScenicListApi = (data) => {
	return Service ({
		url: `/web/scenicManage/myScenic/list`,
		method: "GET",
		data
	});
};

// 我的景点 - 详情
export const myScenictDetailsApi = (scenicId) => {
	return Service ({
		url: `/web/scenicManage/myScenic/${scenicId}`,
		method: "GET",
	});
};

// 我的景点 - 编辑提交
export const myScenictSubmitApi = (data) => {
	return Service ({
		url: `/web/scenicManage/myScenic`,
		method: "PUT",
		data
	});
};

// 我的说说 - 列表
export const myTalkListApi = (data) => {
	return Service ({
		url: `/web/talk/myTalk/list`,
		method: "GET",
		data
	});
};

// 古迹端 发日常 说说
export const sxenicManagePublishTalkApi = (data) => {
	return Service ({
		url: `/web/scenicManage/scenicTalk/publishTalk`,
		method: "POST",
		data
	});
};



// 古迹端  义工列表
export const volunteerActivityListApi = (data) => {
	return Service ({
		url: `/web/scenicManage/volunteerActivity/list`,
		method: "GET",
		data
	});
};

// 古迹端 义工活动新增
export const addVolunteerActivityApi = (data) => {
	return Service ({
		url: `/web/scenicManage/volunteerActivity`,
		method: "POST",
		data
	});
};

//古迹端 义工活动的编辑
export const fixVolunteerActivityApi = (data) => {
	return Service ({
		url: `/web/scenicManage/volunteerActivity`,
		method: "PUT",
		data
	});
};

// 古迹端 义工活动详情
export const volunteerActivityDetailsApi = (activityId) => {
	return Service ({
		url: `/web/scenicManage/volunteerActivity/${activityId}`,
		method: "GET",
	});
};

// 古迹端 义工活动的删除
export const deleteVolunteerActivityDetailsApi = (activityId) => {
	return Service ({
		url: `/web/scenicManage/volunteerActivity/${activityId}`,
		method: "DELETE",
	});
};

// 古迹端 义工活动的下架
export const offlineVolunteerActivityDetailsApi = (activityId) => {
	return Service ({
		url: `/web/scenicManage/volunteerActivity/${activityId}/offline`,
		method: "PUT",
	});
};

// 古迹端 义工活动的下架
export const onlineVolunteerActivityDetailsApi = (activityId) => {
	return Service ({
		url: `/web/scenicManage/volunteerActivity/${activityId}/online`,
		method: "PUT",
	});
};


// 做义工  我的义工信息
export const myVolunteerInfoApi = (data) => {
	return Service ({
		url: `/web/volunteer/myVolunteerInfo`,
		method: "GET",
		data
	});
};

// 做义工 我的义工活动列表
export const myVolunteerActivityApi = (data) => {
	return Service ({
		url: `/web/volunteer/myVolunteerActivity/list`,
		method: "GET",
		data
	});
};

// 义工 报名接口
export const joinVolunteerActivityApi = (volunteerActivityId) => {
	return Service ({
		url: `/web/volunteer/volunteerActivity/${volunteerActivityId}/Apply`,
		method: "POST",
	});
};

// 义工 取消报名
export const unJoinVolunteerActivityApi = (volunteerActivityId) => {
	return Service ({
		url: `/web/volunteer/volunteerActivity/${volunteerActivityId}/cancelApply`,
		method: "POST",
	});
};



// 古迹端  活动列表
export const myActiveListApi = (data) => {
	return Service ({
		url: `/web/scenicManage/activity/list`,
		method: "GET",
		data
	});
};

// 古迹端  活动增加
export const myAddActiveApi = (data) => {
	return Service ({
		url: `/web/scenicManage/activity`,
		method: "POST",
		data
	});
};

// 古迹端 活动编辑
export const myEditorActiveApi = (data) => {
	return Service ({
		url: `/web/scenicManage/activity`,
		method: "PUT",
		data
	});
};

// 古迹端 活动详情界面
export const myActiveDetailApi = (activityId) => {
	return Service ({
		url: `/web/scenicManage/activity/${activityId}`,
		method: "GET",
	});
};

// 古迹端 活动删除
export const deleteMyActiveApi = (activityId) => {
	return Service ({
		url: `/web/scenicManage/activity/${activityId}`,
		method: "DELETE",
	});
};

// 古迹端 活动下架
export const offlineMyActiveApi = (activityId) => {
	return Service ({
		url: `/web/scenicManage/activity/${activityId}/offline`,
		method: "PUT",
	});
};

// 古迹端  活动上架
export const onlineMyActiveApi = (activityId) => {
	return Service ({
		url: `/web/scenicManage/activity/${activityId}/online`,
		method: "PUT",
	});
};


// 古迹端 活动类型标签
export const tagOptionsApi = (data) => {
	return Service ({
		url: `/web/activity/tagOptions`,
		method: "GET",
		data
	});
};




// 商城接口

// 名家列表
export const storeCelebrityListApi = (data) => {
	return Service ({
		url: `/store/product/storeCelebrity`,
		method: "GET",
		data
	});
};

// 标签数据
export const productTagsOptionsApi = (data) => {
	return Service ({
		url: `/store/product/productTagsOptions`,
		method: "GET",
		data
	});
};

// 商品列表
export const productListApi = (data) => {
	return Service ({
		url: `/store/product/list`,
		method: "GET",
		data
	});
};

// 名家详情
export const storeCelebrityApi = (celebrityId) => {
	return Service ({
		url: `/store/product/storeCelebrity/${celebrityId}`,
		method: "GET",
	});
};


// 商品详情
export const productDetailApi = (productId) => {
	return Service ({
		url: `/store/product/${productId}`,
		method: "GET",
	});
};

// 收货列表
export const addressListApi = (data) => {
	return Service ({
		url: `/store/receiptAddress/list`,
		method: "GET",
		data
	});
};

// 新增地址
export const addAddressApi = (data) => {
	return Service ({
		url: `/store/receiptAddress`,
		method: "POST",
		data
	});
};

// 编辑地址
export const fixAddressApi = (data) => {
	return Service ({
		url: `/store/receiptAddress`,
		method: "PUT",
		data
	});
};

// 删除地址
export const deleteAddressApi = (addressId) => {
	return Service ({
		url: `/store/receiptAddress/${addressId}`,
		method: "DELETE",
	});
};

// 设置默认收货地址
export const defaultAddressApi = (receiptAddressId) => {
	return Service ({
		url: `/store/receiptAddress/${receiptAddressId}/defaultAddress`,
		method: "PUT",
	});
};

// 收货地址详情
export const addressDetailsApi = (receiptAddressId) => {
	return Service ({
		url: `/store/receiptAddress/${receiptAddressId}`,
		method: "GET",
	});
};

// 默认收货地址信息
export const defaultAddressInfoApi = (data) => {
	return Service ({
		url: `/store/receiptAddress/defaultReceiptAddress`,
		method: "GET",
		data
	});
};


// 支付相关  单个商品下单                    
export const singleCreateOrderApi = (data) => {
	return Service ({
		url: `/store/order/createOrder/single`,
		method: "POST",
		data
	});
};

// 商品子项列表
export const productItemApi = (productId) => {
	return Service ({
		url: `/store/product/${productId}/productItem`,
		method: "GET",
	});
};

// 订单列表
export const orderListApi = (data) => {
	return Service ({
		url: `/store/order/list`,
		method: "GET",
		data
	});
};

// 订单详情
export const orderDetailtApi = (orderNo) => {
	return Service ({
		url: `/store/order/orderDetail/${orderNo}`,
		method: "GET",
	});
};

// 取消订单
export const cancelOrderApi = (orderNo) => {
	return Service ({
		url: `/store/order/cancelOrder/${orderNo}`,
		method: "PUT",
	});
};

// 退款
export const refundApi = (data) => {
	return Service ({
		url: `/store/order/orderRefund`,
		method: "PUT",
		data
	});
};

// 去付款
export const payOrderApi = (orderNo) => {
	return Service ({
		url: `/store/order/getOrderPayParam/${orderNo}`,
		method: "POST",
	});
};

// 确认收货
export const orderReceiptApi = (orderNo) => {
	return Service ({
		url: `/store/order/orderReceipt/${orderNo}`,
		method: "PUT",
	});
};

// 多个商品下单
export const multipleOrderApi = (data) => {
	return Service ({
		url: `/store/order/createOrder/multiple`,
		method: "POST",
		data
	});
};

// 收货评价 
export const receiptEvaluateApi = (data) => {
	return Service ({
		url: `/store/order/orderReceiptEvaluate`,
		method: "POST",
		data
	});
}

// 评价列表
export const storeEvaluateListApi = (data) => {
	return Service ({
		url: `/store/product/storeEvaluateList`,
		method: "GET",
		data
	});
}


//  首页banner
export const showIndexBannerApi = (data) => {
	return Service ({
		url: `/store/product/showIndexBanner`,
		method: "GET",
		data
	});
};


// 新年活动

// 新年随机祝福
export const changeWishesApi = (data) => {
	return Service ({
		url: `/web/wishes/randomWishes`,
		method: "GET",
		data
	});
};

// 送祝福
export const wishesApi = (data) => {
	return Service ({
		url: `/web/wishes/wishes/save`,
		method: "POST",
		data
	});
};

// 祝福详情
export const wishesDetailsApi = (wishesId) => {
	console.log(wishesId);
	return Service ({
		url: `/web/wishes/wishes/${wishesId}`,
		method: "GET",
	});
};

// 星座运势查询
export const constellationFortuneApi = (data) => {
	return Service ({
		url: `/constellation/constellationFortune`,
		method: "GET",
		params:{
			...data
		}
		
	});
};
// 星座守护套餐
export const comboListApi = () => {
	return Service ({
		url: `/constellation/dispositor/comboList`,
		method: "GET"
	});
};
// 星座守护下单
export const createOrderApi = (data) => {
	return Service ({
		url: `/constellation/dispositor/createOrder`,
		method: "POST",
		data
	});
};
// 星座守护订单关闭
export const closeOrderApi = (orderNo) => {
	return Service ({
		url: `/constellation/dispositor/order/${orderNo}/closeOrder`,
		method: "PUT",
	});
};
// 星座守护订单关闭
export const orderStatusApi = (orderNo) => {
	return Service ({
		url: `/constellation/dispositor/order/${orderNo}/orderStatus`,
		method: "GET",
	});
};
// 星座守护信息
export const userDispositorApi = (data) => {
	return Service ({
		url: `/constellation/dispositor/userDispositor`,
		method: "GET",
		data
	});
};
// 健康星守护 
export const healthDispositorApi = (data) => {
	return Service ({
		url: `/constellation/dispositor/healthDispositor`,
		method: "POST",
		data
	});
};




// 许愿树首页数据
export const indexDataApi = () => {
	return Service ({
		url: `/wish/indexData`,
		method: "GET"
	});
};

// 首页灵气助力记录
export const reikiRecordApi = (data) => {
	return Service ({
		url: `/wish/indexData/reikiRecord`,
		method: "GET",
		data
	});
};

// 许下心愿 
export const userWishApi = (data) => {
	return Service ({
		url: `/wish/userWish`,
		method: "POST",
		data
	});
};

// 心愿助力 
export const reikiHelpApi = (data) => {
	return Service ({
		url: `/wish/wish/reikiHelp`,
		method: "POST",
		data
	});
};

// 心愿详情 
export const userWishIdApi = (userWishId) => {
	return Service ({
		url: `/wish/wish/${userWishId}`,
		method: "GET"
	});
};

// 心愿详情页其他数据 rankingType指的是周或年，默认是周
export const rankingTypeApi = (userWishId, rankingType) => {
	return Service ({
		url: `/wish/wish/${userWishId}/otherData/${rankingType}`,
		method: "GET"
	});
};

// 心愿列表
export const wishListApi = () => {
	return Service ({
		url: `/wish/wishList`,
		method: "GET"
	});
};

// 心愿商品收集 
export const wishProductGatherApi = (data) => {
	return Service ({
		url: `/wish/wishProductGather`,
		method: "POST",
		data
	});
};

// 填写收获地址 
export const fillConsigneeAddressApi = (data) => {
	return Service ({
		url: `/wish/wishProductGather/fillConsigneeAddress`,
		method: "POST",
		data
	});
};

// 心愿商品收集详情 
export const wishProductGatherIdApi = (wishProductGatherId) => {
	return Service ({
		url: `/wish/wishProductGather/${wishProductGatherId}`,
		method: "GET"
	});
};

// 心愿商品收集列表 
export const wishProductGatherListApi = (gatherStatus) => {
	return Service ({
		url: `/wish/wishProductGatherList?gatherStatus=${gatherStatus}`,
		method: "GET"
	});
};

export const gatherSuccessApi = () => {
	return Service ({
		url: `/wish/wishProductGatherList/gatherSuccess`,
		method: "GET"
	});
};
// 心愿商品列表
export const wishProductListApi = () => {
	return Service ({
		url: `/wish/wishProductList`,
		method: "GET"
	});
};

// 心愿排行榜 
export const wishRankingApi = (data) => {
	return Service ({
		url: `/wish/wishRanking`,
		method: "GET",
		data
	});
};

// 心愿助力记录
export const wishReikiRecordApi = (data) => {
	return Service ({
		url: `/wish/wishReikiRecord`,
		method: "GET",
		data
	});
};

// 领取灵气 /wish/wishReikiTalk
export const wishReikiTalkApi = (data) => {
	return Service ({
		url: `/wish/wishReikiTalk`,
		method: "POST",
		data
	});
};

//集愿商品消息读取
export const messageNotifyReadApi = (wishProductGatherId) => {
	return Service ({
		url: `/wish/wishProductGather/${wishProductGatherId}/messageNotifyRead`,
		method: "POST"
	});
};

// 集愿商品消息通知 
export const messageNotifyApi = () => {
	return Service ({
		url: `/wish/wishProductGather/messageNotify`,
		method: "GET"
	});
};

// 编辑心愿 /wish/userWish
export const UserWishChangeApi = (data) => {
	return Service ({
		url: `/wish/userWish`,
		method: "PUT",
		data
	});
};

// 首页用户许愿记录
export const userWishRecordApi = (data) => {
	return Service ({
		url: `/wish/indexData/userWishRecord`,
		method: "GET",
		data
	});
};

// 敲钟配置修改
export const tollConfigApi = (data) => {
	return Service ({
		url: `/ex/toll/tollConfig`,
		method: "PUT",
		data
	});
};

//敲钟数据获取 
export const tollDataApi = () => {
	return Service ({
		url: `/ex/toll/tollData`,
		method: "GET"
	});
};

// 敲钟排名列表
export const tollRankingApi = () => {
	return Service ({
		url: `/ex/toll/tollRanking`,
		method: "GET"
	});
};

// 敲钟次数更新
export const tollTotalUpdateApi = (data) => {
	return Service ({
		url: `/ex/toll/tollTotalUpdate`,
		method: "PUT",
		data
	});
};

//随机取名列表 
export const randomListApi = (data) => {
	return Service ({
		url: `/ex/bename/randomList`,
		method: "GET",
		params:{
			...data
		}
		
	});
};

// 名字详情
export const benameApi = (benameId) => {
	return Service ({
		url: `/ex/bename/${benameId}`,
		method: "GET"
	});
};

// 今日求签子详情
export const toDayGetPegwoodDetailApi = () => {
	return Service ({
		url: `/ex/pegwood/toDayGetPegwoodDetail`,
		method: "GET"
	});
};

// 签子详情
export const pegwoodDetailApi = (pegwoodId) => {
	return Service ({
		url: `/ex/pegwood/pegwoodDetail/${pegwoodId}`,
		method: "GET"
	});
};

// 求签
export const getPegwoodApi = () => {
	return Service ({
		url: `/ex/pegwood/getPegwood`,
		method: "POST"
	});
};

// 每日一物
export const todayThingApi = () => {
	return Service ({
		url: `/ex/thing/todayThing`,
		method: "GET"
	});
};

// 瑞兽信息
export const mythicalListApi = () => {
	return Service ({
		url: `/rs/mythicalList`,
		method: "GET"
	});
};

// 瑞兽守护信息
export const userRsInfoApi = () => {
	return Service ({
		url: `/rs/userRsInfo`,
		method: "GET"
	});
};
// 瑞兽守护信息详情
export const userRsInfoDetailApi = (rsValue) => {
	return Service ({
		url: `/rs/userRsInfoDetail/${rsValue}`,
		method: "GET"
	});
};

//下单 
export const createOrderRsApi = (data) => {
	return Service ({
		url: `/rs/freeCreateOrderOpen`,
		method: "POST",
		data
	});
};
// 关闭订单
export const closeOrderRsApi = (orderNo) => {
	return Service ({
		url: `/rs/order/${orderNo}/closeOrder`,
		method: "PUT"
	});
};

//订单状态 
export const orderStatusRsApi = (orderNo) => {
	return Service ({
		url: `/rs/order/${orderNo}/orderStatus`,
		method: "GET"
	});
};

//订单详情 
export const orderDetailRsApi = (orderNo) => {
	return Service ({
		url: `/rs/order/${orderNo}/orderDetail`,
		method: "GET"
	});
};

// 塔罗牌抽取 
export const randomGetApi = (ignoreTarotNo = null) => {
	return Service ({
		url: `/ex/tarot/randomGet`,
		method: "GET",
		params: {
			...ignoreTarotNo
			
		}
	});
};

//加入购物车
export const AddCartApi = (data) => {
	return Service ({
		url: `/store/cart`,
		method: "POST",
		data
	});
};

// 修改购物车商品
export const ChangeCartApi = (data) => {
	return Service ({
		url: `/store/cart`,
		method: "PUT",
		data
	});
};

// 购物车列表 
export const CartListApi = (data) => {
	return Service ({
		url: `/store/cart/list`,
		method: "GET",
		data
	});
};

// 删除购物车商品 
export const DeleteCartApi = (cartId) => {
	return Service ({
		url: `/store/cart/${cartId}`,
		method: "DELETE"
	});
};