<template>
  <div id="app">
    <navigateBar />
    <router-view />
    <navigateBottom />
  </div>
</template>
  
  <script>
import { webLoginApi } from "@/api/api";
import navigateBar from "./components/navigateBar.vue";
import navigateBottom from "./components/navigatebottom.vue";
export default {
  components: {
    navigateBar,
    navigateBottom,
  },
  data() {
    return {};
  },
  computed: {},
  created() {
    // localStorage.removeItem('code');

    // console.log(location, "window.location");
    if (window.location.search) {
      // 创建一个 URLSearchParams 对象
      let urlParams = new URLSearchParams(location.search);

      // 使用 get 方法提取 'code' 参数的值
      let code = urlParams.get("code");
      // console.log(code, "code");
      localStorage.setItem("code", code);
      this.webLogin();
    }
  },
  methods: {
    webLogin() {
      let code = localStorage.getItem("code");
      console.log(code, "data.code");
      if (code != undefined) {
        webLoginApi(code).then((res) => {
          console.log("webLoginApi", res);
          if (res) {
            localStorage.setItem("tokenKey", res.data.tokenKey);
            localStorage.setItem("sanshengxun-web", res.data.tokenValue);
          }
        });
      }
    },
  },
  mounted() {
    // 可以在这里进行组件挂载后的操作
    // if (this.$route.path !== "/preload") {
    const token = localStorage.getItem("tokenValue"); // 使用正确的 token 键名
    // console.log('token', token, localStorage);

    if (token) {
      this.$router.push("/");
    } else {
      this.$router.push("/preload"); 
    }

    // }
  },
};
</script>
  
  <style lang="scss">
/* 在这里编写你的样式 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
}
</style>