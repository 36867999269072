<template>
  <div class="main-content">
    <!-- <home-particles></home-particles> -->
    <div class="themes">
      <div class="themes-top">
        <div
          id="show"
          @mousedown="showMouseDown"
          @mousemove="showMouseMove"
          @mouseup="showMouseUp"
          @click="showClick"
        >
          <div
            :class="'item item' + index"
            v-for="(item, index) in items"
            :key="item.id"
            @click="itemClick(item)"
            :style="{
              top: item.top + 'px',
              left: item.left + 'px',
              zIndex: item.Zindex,
              width: item.width + 'px',
              height: item.height + 'px',
              opacity: item.opacity,
              transform: 'rotate(' + item.rotate + 'deg)',
            }"
          >
            <!-- item 内容 -->
            <div class="items-box"></div>
          </div>
        </div>
        <div class="touch-bottom" @click="themsClick()"> 
          <div class="tb-top">
            <div class="t-border"></div>
          </div>
          <div class="tb-bottom" @click="themsClick()">
            {{ themesName }}
          </div>
        </div>
      </div>

      <div class="themes-center">
        <!-- 求签 -->
        <div class="sign" @click="drawClick()">
          <div class="sign-img">
            <img
              src="https://www.hereitis.cn/profile/adminUpload/2024/07/30/c44025e273f448eeb69eb57d6133a69b.png"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <!-- 撞钟 -->
        <div class="bell" @click="bellClick()">
          <div class="bell-img">
            <img
              src="https://www.hereitis.cn/profile/adminUpload/2024/07/30/0ffc802d8f054d72ae939e350b4c61bf.png"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <!-- 每日百科 -->
        <div class="today-thing" @click="todayThingClick()">
          <div class="today-thing-img">
            <img
              src="https://www.hereitis.cn/profile/adminUpload/2024/07/30/27b580746d2f4b308c7e4ef16bba84bc.png"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
  
  <script>

// import homeParticles from "@/components/home-particles.vue";
// 全局定义节流函数
function throttle(func, delay) {
  let timer = null;
  return function () {
    if (!timer) {
      timer = setTimeout(() => {
        func.apply(this, arguments);
        timer = null;
      }, delay);
    }
  };
}
export default {
  name: "home-page",
  components: {
    // homeParticles,
  },
  data() {
    return {
      clicked: false,
      isMove: false,
      isJump: true,
      bgColor: "#00000000",
      danmuFlag: true,
      show: true,
      avatar: "https://clanss.com/applet/image/yearsImg/denglong.gif",
      items: [
        {
          id: 0,
          top: 0,
          left: 0,
          rotate: 0,
          label: "心语心愿",
          url: "/wish",
          isClick: false,
        },
        {
          id: 1,
          top: 0,
          left: 0,
          rotate: 0,
          label: "文心字斋",
          url: "/getName",
          isClick: false,
        },
        {
          id: 2,
          top: 0,
          left: 0,
          rotate: 0,
          label: "山海百灵",
          url: "/creatures",
          isClick: false,
        },
        {
          id: 3,
          top: 0,
          left: 0,
          rotate: 0,
          label: "福星高照",
          url: "/constellation",
          isClick: false,
        },
        {
          id: 4,
          top: 0,
          left: 0,
          rotate: 0,
          label: "星罗棋布",
          url: "/tarot",
          isClick: false,
        },
      ],
      maxHeightItem: {
        label: "",
        url: "",
        marginTop: 0,
      },
      themesName: "山海百灵",
      showerWidth: 800,
      showerHeight: 500,
      r: ((Math.PI / 180) * 360) / 5,
      cR: 0,
      ccR: 0,
      timer: null,
      startX: 0,
      frequency: 0,
      url: "",
      activeList: [
        {
          id: 1,
          title: "匠心大师",
          desc: "名家手作",
          icon: "https://clanss.com/applet/image/home-icon/great-icon.png",
        },
        {
          id: 2,
          title: "市集寻觅",
          desc: "吉祥好物",
          icon: "https://clanss.com/applet/image/home-icon/shop-icon.png",
        },
        {
          id: 3,
          title: "守护计划",
          desc: "申请守护",
          icon: "https://clanss.com/applet/image/home-icon/watch-icon.png",
        },
        {
          id: 4,
          title: "义工联盟",
          desc: "我要加入",
          //
          icon: "https://clanss.com/applet/image/home-icon/volunteer-icon.png",
        },
      ],
    };
  },
  watch: {
    startX(newValue) {
      if (200 < newValue < 300) {
        this.isMove = true;
      } else {
        this.isMove = false;
      }
      console.log("startX", newValue, this.isMove);
    },
  },
  created() {
    this.$nextTick(() => {
      this.rotate();
      this.frequency = 0;
      this.timer = null;
      this.itemClick(this.items[0]);
      // this.webTokenLogin();
      
    });
  },
  
  mounted() {},
  methods: {
    themsClick(){
      console.log(this.url);
      this.$router.push(this.url);
    },
   
    // 点击事件处理函数，通过节流函数进行包装
    itemClick: throttle(function (item) {
      console.log(item);
      clearInterval(this.timer);
      this.cR = Math.PI / 2 - item.rotate;
      this.timer = setInterval(() => this.rotate(), 10);
      this.themesName = item.label;
      this.frequency++;

      // 执行你的点击逻辑
      console.log("this.frequency", this.frequency);
      //   this.frequency > 1 && this.frequency < 3 &&
      if (this.frequency > 1 && this.frequency < 3 && this.isMove == true) {
        item.isClick = true;
        if (item.isClick) {
          console.log(item.url, "item.url");
          this.$router.push(item.url);
          console.log(this.$route);
        }
      }
    }, 500), // 500毫秒的节流间隔,
    showMouseDown(event) {
      console.log(event, "showMouseDown");
      this.startX = event.clientX;
      this.timer = this.timer || setInterval(() => this.rotate(), 10);
    },
    showMouseMove(event) {
      if (event.buttons === 0) return;

      var currentX = event.clientX;
      var deltaX = currentX - this.startX;

      if (Math.abs(deltaX) > 50) {
        if (deltaX > 0) {
          this.cR = (this.cR - this.r + 2 * Math.PI) % (2 * Math.PI);
          this.timer = this.timer || setInterval(() => this.rotate(), 10);
        } else {
          this.cR = (this.cR + this.r + 2 * Math.PI) % (2 * Math.PI);
          this.timer = this.timer || setInterval(() => this.rotate(), 10);
        }

        this.startX = currentX;
      }
    },
    showMouseUp() {
      clearInterval(this.timer);
      this.timer = null;
    },
    rotate() {
      this.ccR = (this.ccR + 2 * Math.PI) % (2 * Math.PI);
      if (this.cR - this.ccR < 0) {
        this.cR = this.cR + 2 * Math.PI;
      }
      if (this.cR - this.ccR < Math.PI) {
        this.ccR = this.ccR + (this.cR - this.ccR) / 19;
      } else {
        this.ccR = this.ccR - (2 * Math.PI + this.ccR - this.cR) / 19;
      }
      if (
        Math.abs(
          ((this.cR + 2 * Math.PI) % (2 * Math.PI)) -
            ((this.ccR + 2 * Math.PI) % (2 * Math.PI))
        ) <
        Math.PI / 720
      ) {
        this.ccR = this.cR;
        // clearInterval(this.timer);
      }
      for (var i = 0; i < this.items.length; i++) {
        // var item = this.items[i];
        var w, h, alph;
        var sinR = Math.sin(this.r * i + this.ccR);
        var cosR = Math.cos(this.r * i + this.ccR);
        w = 240 + 0.3 * 240 * sinR - 100;
        h = 280 + 0.3 * 280 * sinR - 100;
        alph = sinR > 0 ? sinR * 0.1 + 0.82 : 0.6;
        var rotate = (this.r * i + 2 * Math.PI) % (2 * Math.PI);

        this.$set(this.items, i, {
          id: this.items[i].id,
          label: this.items[i].label,
          url: this.items[i].url,
          marginTop: parseInt(
            this.showerHeight / 2 +
              (sinR * this.showerWidth) / 2 / 3 +
              100 -
              w / 2
          ),
          top: parseInt(
            this.showerHeight / 2 +
              (sinR * this.showerWidth) / 2 / 5 -
              w / 2 -
              100
          ),
          left: parseInt(
            (this.showerWidth / 3 + (cosR * this.showerWidth) / 3 - h / 2) / 1.5
          ),
          width: w,
          height: h,
          Zindex: parseInt(
            this.showerHeight / 2 + (sinR * this.showerWidth) / 2 / 3 - w / 2
          ),
          opacity: alph,
          rotate: rotate,
        });
      }

      let maxHeightItem = this.items[0];
      for (let i = 0; i < this.items.length; i++) {
        // console.log(this.items[i].height, maxHeightItem.height);
        if (this.items[i].width > maxHeightItem.width) {
          maxHeightItem = this.items[i];
          this.themesName = maxHeightItem.label;
          this.url = maxHeightItem.url;
        } else {
          this.themesName = maxHeightItem.label;
          this.url = maxHeightItem.url;
        }
      }
    },
    showClick(event) {
      console.log(event, "showClick");
      this.startX = event.touches[0].clientX;
      // console.log(event);
      this.timer = this.timer || setInterval(() => this.rotate(), 10);
    },
    showTouchMove(event) {
      // console.log(event.touches[0].clientX);
      // 当前触点位置
      var currentX = event.touches[0].clientX;
      // 计算滑动的偏移量
      var deltaX = currentX - this.startX;
      // console.log(Math.abs(deltaX));

      if (Math.abs(deltaX) > 50) {
        // event.preventDefault();

        // 判断是左滑还是右滑
        if (deltaX > 0) {
          this.cR = (this.cR - this.r + 2 * Math.PI) % (2 * Math.PI);
          this.timer = this.timer || setInterval(() => this.rotate(), 10);
          // console.log('向右滑动');
          // 右滑的处理逻辑
        } else {
          this.cR = (this.cR + this.r + 2 * Math.PI) % (2 * Math.PI);
          this.timer = this.timer || setInterval(() => this.rotate(), 10);
          // console.log('向左滑动');
          // 左滑的处理逻辑
        }

        // 更新开始的触摸位置
        this.startX = currentX;
      }
    },
    bellClick() {
      this.$router.push({
        path: "/bell",
      });
    },
    drawClick() {
      this.$router.push({
        path: "/draw",
      });
    },
    todayThingClick() {
      this.$router.push({
        path: "/todayThing",
      });
    },
  },
  beforeDestroy(){
    // window.localStorage.removeItem('code');

  }
};
</script>
  
  <style lang="scss" scoped>
.main-content {
  width: 100vw;
  height: 100vh;
  position: relative;
  // overflow: hidden;
  // background: url('https://clanss.com/applet/image/home/landscape.png') no-repeat center/cover;
  background-size: 100% 100%;
  overflow: hidden;
  // background: linear-gradient(to bottom, #160c35, #120724);
  background: linear-gradient(180deg, #1d0e52 0%, #080410 33%, #17092d 100%);
  z-index: 1;

  .starry {
    z-index: -1;
    position: absolute;
  }

  .themes {
    width: 100%;
    position: relative;
    top: 2.5rem;
    z-index: 999;
    display: flex;
    flex-direction: column;
    // height: 100vh; /* 使容器的高度占满视口 */
  }
  .themes-top,
  .themes-bottom,
  .themes-center {
    // flex: 1;
    // height: 50vh;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    font-size: 12px;
    color: #fff;
  }
  .themes-top {
    height: 60vh;
  }
  .themes-center {
    height: 30vh;
  }

  #show {
    position: relative;
    margin: 0px auto;
    width: 64%;
    height: 60%;
    left: calc(100vw - 80%);
    top: 0rem;
    // transform: translate(30% 0px);

    .item {
      position: absolute;
      height: 140px;
      width: 60px;
      // background: #999999;
      // border: 1px solid #eeeeee;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      outline: none;
    }

    .item0 .items-box {
      width: 100%;
      height: 100%;
      background: url("https://www.hereitis.cn/profile/adminUpload/2024/06/06/424ab89170fc4770983915d0b081f054.png")
        no-repeat center/cover;
      background-size: 78% 100%;
    }

    .item1 .items-box {
      width: 100%;
      height: 100%;
      transform: rotate(-1deg);
      background: url("https://www.hereitis.cn/profile/adminUpload/2024/06/06/6c64be66aebf4b758d4b19a1a6521553.png")
        no-repeat center/cover;
      background-size: 78% 100%;
    }

    .item2 .items-box {
      width: 100%;
      height: 100%;
      transform: rotate(-3deg);
      background: url("https://www.hereitis.cn/profile/adminUpload/2024/06/06/ad867aef16544f0d805e685a43b5b056.png")
        no-repeat center/cover;
      background-size: 78% 100%;
    }

    .item3 .items-box {
      width: 100%;
      height: 100%;
      transform: rotate(-4deg);
      background: url("https://www.hereitis.cn/profile/adminUpload/2024/06/06/b229f22919914026b35c68abf50f46d4.png")
        no-repeat center/cover;
      background-size: 78% 100%;
    }

    .item4 .items-box {
      width: 100%;
      height: 100%;
      transform: rotate(-5deg);
      background: url("https://www.hereitis.cn/profile/adminUpload/2024/06/06/9683feedd6b24c4fbb188944befd4bc2.png")
        no-repeat center/cover;
      background-size: 78% 100%;
    }
  }
  .themes-top {
    display: flex;
    flex-direction: column;
  }

  .touch-bottom {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    position: relative;
    // top: 302px;
    margin-top: 80px;

    .tb-top {
      text-align: center;
      width: 23.8125rem;
      height: 23.3125rem;
      // margin: 0 auto;
      position: relative;
      top: 8.95rem;
      left: 38vw;
      background: url("https://clanss.com/applet/image/home/lotus.png")
        no-repeat center;
      background-size: 100% 100%;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      transform: rotateX(5deg);
      // opacity: 0.7;

      .t-border {
        width: 80%;
        height: 80%;
        text-align: center;
        // border: 5rpx solid #aaaaff;
        border-radius: 50%;
        position: relative;
        top: 8%;
        transform: rotateX(45deg);
      }

      .t-border {
        border: 10px solid transparent;
        border-radius: 50%;
        /* 圆形形状 */
        background: radial-gradient(
          circle at center,
          transparent 16%,
          rgba(83, 77, 217, 0.1) 100%
        );
        background-clip: border-box;
        transform: rotateX(5deg);
        position: relative;
        top: 1.5625rem;
        left: 2.5rem;
        /* 使背景限制在边框内 */
      }

      @keyframes huerotate {
        0% {
          filter: hue-rotate(0deg);
        }

        100% {
          filter: hue-rotate(360deg);
        }
      }
    }

    .tb-bottom {
      width: 17.9375rem;
      height: 3.1875rem;
      // margin: 0 auto;
      background: url("https://clanss.com/applet/image/home/themes-btn.png")
        no-repeat center;
      background-size: cover;
      //   font-family: Source Han Sans CN, Source Han Sans CN;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 1.5rem;
      color: #ffffff;
      line-height: 3.1875rem;
      text-align: center;
      font-style: normal;
      text-transform: none;
      position: relative;
      top: 6.125rem;
      left: 41.5vw;
      z-index: 2;
      //   top: -50px;
      //   letter-spacing: 2.5px;
    }
  }
  .themes-center {
    width: 98%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    // justify-content: space-between;
    z-index: 1;
  }

  .themes-center .sign {
    width: 150px;
    height: 63px;
    margin-left: 20px;
    z-index: 9999;
    .sign-img {
      width: 100%;
      height: 63px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .themes-center .bell {
    width: 150px;
    height: 63px;
    margin-left: 20px;
    .bell-img {
      width: 100%;
      height: 63px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .themes-center .today-thing {
    width: 150px;
    height: 63px;
    margin-left: 20px;
    .today-thing-img {
      width: 100%;
      height: 63px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .themes-bottom {
    width: 100%;
    height: 15px;
    // background-color: #fff;
    position: absolute;
    bottom: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .antiquities,
    .encyclopedia {
      width: 167px;
      height: 60px;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 10px;
      // filter: blur(10px);
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .antiquities-title .title-top {
      width: 82px;
      height: 30px;
      margin-left: 10px;
      text-align: center;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 12px;
      color: #ee9d60;
      line-height: 45px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .encyclopedia-title .title-top {
      width: 82px;
      height: 30px;
      margin-left: 10px;
      text-align: center;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 12px;
      color: #ffe3bf;
      line-height: 45px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .antiquities-title .title-bottom {
      width: 82px;
      margin-left: 10px;
      text-align: center;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 12px;
      color: #ee9d60;
      line-height: 25px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .encyclopedia-title .title-bottom {
      width: 82px;
      margin-left: 10px;
      text-align: center;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 12px;
      color: #ffe3bf;
      line-height: 25px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .antiquities .antiquities-img,
    .encyclopedia .encyclopedia-img {
      width: 60px;
      height: 60px;
    }

    .antiquities-img {
      // antiquities
      background: url("https://clanss.com/applet/image/home/antiquities.png")
        no-repeat;
      background-size: contain;
    }

    .encyclopedia-img {
      background: url("https://clanss.com/applet/image/home/today-thing.png")
        no-repeat;
      background-size: contain;
    }
  }
}
</style>