<template>
  <div class="navigate-bar" v-if="!isLoadingPage">
    <div class="bar-box">
      <div class="box-left">
        <div class="left-logo">
          <img
            src="https://www.hereitis.cn/profile/adminUpload/2024/07/30/ed6110f6b3e94aa6a50e6d8c48259c0f.png"
            alt=""
            srcset=""
          />
        </div>
        <div
          v-if="!isHomePage && !isLoadingPage"
          @click="goHome"
          class="go-home"
        >
          <div class="gh-left">
            <img
              src="https://www.hereitis.cn/profile/adminUpload/2024/08/09/2cc9727cfe0a4bf4ab608d25728c2e9f.png"
              alt=""
              srcset=""
            />
          </div>
          <div class="gh-right">回首页</div>
        </div>
      </div>
      <div class="box-right">
        <div class="right-icon" @click="SmallprogramClick">
          <img
            src="https://www.hereitis.cn/profile/adminUpload/2024/07/30/4906966978d5428dae1258648ed245a4.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="right-btn">
          <div class="btn-box" @click="goLogin()">注册/登录</div>
        </div>
      </div>
    </div>
    <div :class="islogin ? 'login loginMove' : 'login'" v-if="!isUserLoggedIn">
      <p class="wx-title">微信扫码立即登录</p>
      <div class="wx-img" id="wxImg"></div>

      <div class="wx-privacy">
        <div class="check-box">
          <el-checkbox v-model="checked">
            同意并阅读《三生寻用户隐私政策》
          </el-checkbox>
        </div>
      </div>
    </div>
    <div class="small-program" v-if="isProgram">
      <img src="https://www.hereitis.cn/profile/adminUpload/2024/08/30/5f49adebb5f345d18cf1ee3a6c195f10.png" alt="" srcset="">
    </div>
  </div>
</template>

<script src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
export default {
  data() {
    return {
      checked: true,
      islogin: false,
      isProgram: false,
      isUserLoggedIn: false, // 新增字段
    };
  },
  computed: {
    isHomePage() {
      return this.$route.path === "/";
    },
    isLoadingPage() {
      return this.$route.path === "/preload";
    },
  },
  created() {
    // 检查是否已登录
    this.checkLoginStatus();

    if (window.location.search) {
      let urlParams = new URLSearchParams(location.search);
      let code = urlParams.get("code");
      localStorage.setItem("code", code);
      this.webLogin();
    }
  },
  methods: {
    SmallprogramClick() {
      setTimeout(() => {
        this.isProgram = !this.isProgram;
      }, 50);
    },
    getWx() {
      this.$nextTick(() => {
        var redirectUri = encodeURIComponent("https://clanss.com");
        let wxImg = document.getElementById("wxImg");
        var obj = new WxLogin({
          self_redirect: false,
          id: 'wxImg',
          appid: "wx60e45f432f325967",
          scope: "snsapi_login",
          redirect_uri: redirectUri,
          state: "",
          style: "",
          href: "",
        });
      });
    },
    goHome() { 
      this.$router.push("/");
    },
    goLogin() {
      if (!this.isUserLoggedIn) {
        this.islogin = !this.islogin;
        this.getWx();
      }
    },
    checkLoginStatus() {
      let tokenKey = localStorage.getItem("tokenKey");
      console.log(tokenKey,'tokenKey');
      
      this.isUserLoggedIn = !tokenKey; // 如果 tokenKey 存在则用户已登录
    },
    webLogin() {
      let code = localStorage.getItem("code");
      if (code != undefined) {
        webLoginApi(code).then((res) => {
          if (res) {
            localStorage.setItem("tokenKey", res.data.tokenKey);
            localStorage.setItem("sanshengxun-web", res.data.tokenValue);
            this.isUserLoggedIn = true; // 登录成功，更新状态
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.go-home {
  height: 100%;
  display: flex;
  margin-left: 1.425rem;
  .gh-left {
    width: 1.0625rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 1.0625rem;
      height: 1.0625rem;
    }
  }
  .gh-right {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 1rem;
    color: #ffffff;
    line-height: 3.75rem;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-left: 0.25rem;
  }
}
.van-button--mini {
  font-size: 0.08rem !important;
  height: 0.3rem !important;
  width: 0.63rem !important;
  border-radius: 0.03rem !important;
}
.navigate-bar {
  width: 100vw;
  height: 3.75rem;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0rem;
  z-index: 9999;
  // background: #02050e;
  border-radius: 0px 0px 0px 0px;
}
.bar-box {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.box-left {
  display: flex;
  .left-logo {
    width: 6.23rem;
    height: 100%;
    margin-left: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 2.3rem;
      display: block;
      text-align: center;
    }
  }
}
.box-right {
  width: 10rem;
  margin-right: 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .right-icon {
    width: 2rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      width: 1.625rem;
      height: 1.625rem;
      text-align: center;
    }
  }
  .right-btn {
    width: 6.873rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-box {
      width: 5.8rem;
      height: 2rem;
      background: #ed9b60;
      border-radius: 0.3123rem;
      line-height: 2rem;
      color: #fff;
      text-align: center;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 0.8rem;
      font-style: normal;
      text-transform: none;
    }
  }
}
.login {
  position: absolute;
  top: -31.25rem;
  left: 38vw;
  width: 25rem;
  height: 30.8125rem;
  background: linear-gradient(180deg, #fce4cb 0%, #fefefe 100%);
  box-shadow: 0rem 0.1875rem 0.375rem 0.0625rem rgba(0, 0, 0, 0.16);
  border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
  .wx-title {
    width: 100%;
    height: 100px;
    text-align: center;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: bold;
    font-size: 24px;
    color: #995b2e;
    line-height: 100px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .wx-img {
    width: 100%;
    height: 335px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      text-align: center;
      width: 208px;
      height: 208px;
      border-radius: 0px 0px 0px 0px;
      border: 1px solid #e88c5d;
      // opacity: 0.5;
    }
  }
  .wx-privacy {
    width: 100%;
    height: 60px;
    background: #f0f0f0;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .check-box {
      width: 60%;
      margin: 0 auto;
    }
  }
}
.loginMove {
  transition: 0.6s;
  animation: loginMove 1s ease-in-out forwards;
}
@keyframes loginMove {
  0% {
    top: -31.25rem;
  }
  100% {
    top: 4.8rem;
  }
}
.small-program{
  width: 200px;
  height: 200px;
  position: absolute;
  top: 70px;
  right: 100px;
  background-color: #f0f0f0;
  border-radius: 20px;
  transition: all 0.6s;
  img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
}
</style>